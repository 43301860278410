/**
 * Line-Up Ticketing widget.
 *
 * This is the main entry point into the app, it exposes the LineupTicketing
 * instance in the DOM, which can be used to start the ticketing widget
 *
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "iframe-resizer/js/iframeResizer.contentWindow";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import thunkMiddleware from "redux-thunk";
import { createBrowserHistory } from "history";
import Root from "./containers/Root";
import createRootReducer, { initialState } from "./reducers";
import CustomColors from "utils/Colors";
import { googleAnalytics } from "./reactGAMiddleware";
import { fbPixel } from "./fbMiddleware";

class LineupTicketing {
  startApplication = (elementId, options) => {
    const defaults = {};
    const opts = Object.assign({}, defaults, options);
    const element = document.getElementById(elementId);

    if (!element) {
      throw new Error(
        "Could not find " + elementId + " to attach application to"
      );
    }

    if (
      !opts.eventId &&
      !opts.productId &&
      !opts.collectionId &&
      !opts.collections
    ) {
      console.warn("Could not find Event ID set in options");
    }

    let { history, store } = this.setupReactReduxApplication(opts);

    return ReactDOM.render(<Root history={history} store={store} />, element);
  };

  /**
   * Merge in data from options and any set cookies into the app's state
   *
   * @param {object} initialState The initial state
   * @param {object} options The options passed into the app by the user
   */
  updateInitialState = (initialState, options) => {
    // this will figure out if the eventId is an actual ID or a slug
    try {
      let id = parseInt(options.eventId);
      if (id > 0) {
        initialState.event.item.id = id;
      } else {
        throw new Error("nan");
      }
    } catch (e) {
      initialState.event.item.slug = options.eventId;
    }

    if (options.performanceId) {
      let performanceId = parseInt(options.performanceId);
      initialState.selectedPerformance.id = performanceId;
    }
    if (options.productId) {
      initialState.product.item.id = parseInt(options.productId);
    } else if (options.collectionId) {
      initialState.collection.item.id = parseInt(options.collectionId);
    } else if (options.collections) {
      initialState.collections = JSON.parse(options.collections);
    }

    let authState = {
      apiKey: options.apiKey,
      apiSecretKey: options.apiSecretKey,
      searchApiKey: options.searchApiKey,
      searchIndex: options.searchIndex,
    };

    // update state
    initialState.auth = Object.assign({}, initialState.auth, authState);

    // pass options into the app state, under the settings key
    let settingsKeys = [
      "mainColor",
      "backgroundColor",
      "showCard",
      "singlePerformance",
    ];
    let settingsData = {
      referrer: document.referrer,
    };

    for (let key of settingsKeys) {
      if (options[key] !== undefined) {
        settingsData[key] = options[key];
      }
    }
    const newSettings = Object.assign({}, initialState.settings, settingsData);
    const customColors = new CustomColors(newSettings);
    settingsData = customColors.processColorSettings();
    initialState.settings = Object.assign(
      {},
      initialState.settings,
      settingsData
    );

    return initialState;
  };

  setupReactReduxApplication = (options) => {
    const history = createBrowserHistory();
    let coreMiddlewares = [thunkMiddleware];

    if (process.env.NODE_ENV === "development") {
      const { logger } = require(`redux-logger`);
      coreMiddlewares.push(logger);
    } else {
      coreMiddlewares = [...coreMiddlewares, googleAnalytics, fbPixel];
    }

    const newInitialState = this.updateInitialState(initialState, options);
    const store = createStore(
      createRootReducer(history), // root reducer with router state
      newInitialState,
      compose(applyMiddleware(routerMiddleware(history), ...coreMiddlewares))
    );
    return {
      history,
      store,
    };
  };

  stopApplication = (elementId) => {
    console.log("Stopping application");
  };
}

// export to DOM window
window.LineupTicketing = new LineupTicketing();

const getQueryParams = (qs) => {
  qs = qs.split("+").join(" ");
  var params = {},
    tokens,
    re = /[?&]?([^=]+)=([^&]*)/g;
  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }
  return params;
};

var options = {};
var urlOptions = getQueryParams(document.location.search);
for (var attrName in urlOptions) {
  var val = urlOptions[attrName];
  if (val === "true") {
    options[attrName] = true;
  } else if (val === "false") {
    options[attrName] = false;
  } else {
    options[attrName] = val;
  }
}

window.LineupTicketing.startApplication("line-up", options);
