import { push } from "connected-react-router";
import Cookies from "cookies-js";
import store from "store";
import Api from "utils/Api";
import { SubmissionError } from "redux-form";
import { getBasketItemCount, getBasketTotal } from "../selectors";
import {
  CreateReservationError,
  FetchPerformancesError,
  FetchCustomersError,
} from "utils/Error";
import { parseApiError, networkError } from "utils/Utils";

export const FETCH_EVENTS_START = "FETCH_EVENTS_START";
export const FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS";
export const FETCH_EVENTS_FAIL = "FETCH_EVENTS_FAIL";

export const fetchEvents = () => {
  return (dispatch, getState) => {
    dispatch(fetchEventsStart());

    let request = Api.getEvents(getState().auth);

    request
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((err) => Promise.reject(err));
        }
      })
      .then((json) => dispatch(fetchEventsSuccess(json)))
      .catch((err) => dispatch(fetchEventsFail(err)));

    return request;
  };
};

const fetchEventsStart = () => {
  return {
    type: FETCH_EVENTS_START,
  };
};

const fetchEventsFail = (response) => {
  return {
    type: FETCH_EVENTS_FAIL,
    response: response,
  };
};

const fetchEventsSuccess = (response) => {
  return {
    type: FETCH_EVENTS_SUCCESS,
    response: response,
  };
};

export const SELECT_EVENT = "SELECT_EVENT";
export const selectEvent = (event) => {
  return {
    type: SELECT_EVENT,
    event: event,
  };
};

export const FETCH_STRIPE_SETTINGS_START = "FETCH_STRIPE_SETTINGS_START";
export const FETCH_STRIPE_SETTINGS_SUCCESS = "FETCH_STRIPE_SETTINGS_SUCCESS";
export const FETCH_STRIPE_SETTINGS_FAIL = "FETCH_STRIPE_SETTINGS_FAIL";

const fetchStripeSettingsStart = () => {
  return {
    type: FETCH_STRIPE_SETTINGS_START,
  };
};

const fetchStripeSettingsFail = (response) => {
  return {
    type: FETCH_STRIPE_SETTINGS_FAIL,
    response: response,
  };
};

const fetchStripeSettingsSuccess = (response) => {
  return {
    type: FETCH_STRIPE_SETTINGS_SUCCESS,
    response: response,
  };
};

export const fetchStripeSettings = () => {
  return (dispatch, getState) => {
    dispatch(fetchStripeSettingsStart());

    return Api.getStripeSettings(getState().auth).then(
      (response) => {
        if (response.ok) {
          return response
            .json()
            .then((json) => dispatch(fetchStripeSettingsSuccess(json)));
        } else {
          return response
            .json()
            .then((err) => dispatch(fetchStripeSettingsFail(err)));
        }
      },
      (err) => dispatch(fetchStripeSettingsFail(err))
    );
  };
};

export const FETCH_EVENT_START = "FETCH_EVENT_START";
export const FETCH_EVENT_SUCCESS = "FETCH_EVENT_SUCCESS";
export const FETCH_EVENT_FAIL = "FETCH_EVENT_FAIL";

export const fetchEvent = (eventId) => {
  return (dispatch, getState) => {
    dispatch(fetchEventStart());

    let request = Api.getEvent(eventId, getState().auth);

    request
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((err) => Promise.reject(err));
        }
      })
      .then((json) => dispatch(fetchEventSuccess(json)))
      .catch((err) => dispatch(fetchEventFail(err)));

    return request;
  };
};

const fetchEventStart = () => {
  return {
    type: FETCH_EVENT_START,
  };
};

const fetchEventFail = (response) => {
  return {
    type: FETCH_EVENT_FAIL,
    response: response,
  };
};

const fetchEventSuccess = (response) => {
  return {
    type: FETCH_EVENT_SUCCESS,
    response: response,
  };
};

export const REQUEST_TICKETS = "REQUEST_TICKETS";
export const RECEIVE_TICKETS = "RECEIVE_TICKETS";
export const REQUEST_TICKETS_FAIL = "REQUEST_TICKETS_FAIL";
export const INVALIDATE_PERFORMANCE = "INVALIDATE_PERFORMANCE";

export const SELECT_PERFORMANCE = "SELECT_PERFORMANCE";
export const SELECT_DATE = "SELECT_DATE";

const requestTickets = (performanceId) => ({
  type: REQUEST_TICKETS,
  performanceId,
});

const receiveTickets = (performanceId, json) => ({
  type: RECEIVE_TICKETS,
  performanceId,
  response: json,
  receivedAt: Date.now(),
});

const requestTicketsFailed = (performanceId, json) => ({
  type: REQUEST_TICKETS_FAIL,
  performanceId,
  response: json,
});

const fetchTickets = (performanceId, state) => {
  return (dispatch) => {
    dispatch(requestTickets(performanceId));
    let request = Api.fetchTicketsForPerformance(performanceId, state.auth);
    return request
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response
            .json()
            .then((errResponse) => Promise.reject(errResponse));
        }
      })
      .then((json) => dispatch(receiveTickets(performanceId, json)))
      .catch((errResponse) =>
        dispatch(requestTicketsFailed(performanceId, errResponse))
      );
  };
};

const shouldFetchTickets = (state, performanceId) => {
  const tickets = state.ticketsByPerformance[performanceId];
  if (!tickets) {
    return true;
  }
  if (tickets.isFetching) {
    return false;
  }
  return true;
};

export function fetchTicketsIfNeeded(performanceId) {
  return (dispatch, getState) => {
    if (shouldFetchTickets(getState(), performanceId)) {
      return dispatch(fetchTickets(performanceId, getState()));
    }
  };
}

export const selectPerformance = (performance) => ({
  type: SELECT_PERFORMANCE,
  performance,
});

export const selectDate = (date) => ({
  type: SELECT_DATE,
  date,
});

export const FETCH_CARDS_START = "FETCH_CARDS_START";
export const FETCH_CARDS_FAIL = "FETCH_CARDS_FAIL";
export const FETCH_CARDS_SUCCESS = "FETCH_CARDS_SUCCESS";

export const fetchCards = () => {
  return (dispatch, getState) => {
    dispatch(fetchCardsStart());
    return Api.getCards(getState().auth).then(
      (response) => {
        if (response.ok) {
          return response
            .json()
            .then((json) => dispatch(fetchCardsSuccess(json)));
        } else {
          return response.json().then((json) => dispatch(fetchCardsFail(json)));
        }
      },
      (err) => dispatch(fetchCardsFail(err.message))
    );
  };
};

const fetchCardsStart = () => {
  return {
    type: FETCH_CARDS_START,
  };
};

const fetchCardsSuccess = (response) => {
  return {
    type: FETCH_CARDS_SUCCESS,
    response: response,
  };
};

const fetchCardsFail = (error) => {
  return {
    type: FETCH_CARDS_FAIL,
    error: error,
  };
};

export const FETCH_PERFORMANCES_START = "FETCH_PERFORMANCES_START";
export const FETCH_PERFORMANCES_FAIL = "FETCH_PERFORMANCES_FAIL";
export const FETCH_PERFORMANCES_SUCCESS = "FETCH_PERFORMANCES_SUCCESS";
export const FETCH_ALL_PERFORMANCES_SUCCESS = "FETCH_ALL_PERFORMANCES_SUCCESS";

const fetchPerformances = (eventId, page) => {
  return (dispatch, getState) => {
    let request = Api.getPerformances(eventId, getState().auth, page);
    return request
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response
            .json()
            .then((err) => Promise.reject(new FetchPerformancesError(err)));
        }
      })
      .then((json) => {
        dispatch(fetchPerformancesSuccess(json));
      })
      .catch((err) => dispatch(fetchPerformancesFail(err.response)));
  };
};

export const fetchAllPerformances = (eventId) => {
  return (dispatch, getState) => {
    dispatch(fetchPerformancesStart());
    let page = 1;
    return dispatch(fetchPerformances(eventId, page)).then(() => {
      page++;
      let promises = [];
      while (page <= getState().performances.pages) {
        promises.push(dispatch(fetchPerformances(eventId, page)));
        page++;
      }
      Promise.all(promises)
        .then(() => {
          let performances = getState().performances.items;
          let selectedPerformance = getState().selectedPerformance;
          let selectPerf;
          if (selectedPerformance.id) {
            selectPerf = performances.find(
              (perf) => perf.id === selectedPerformance.id
            );
          }
          if (selectPerf) {
            dispatch(selectPerformance(selectPerf));
            dispatch(closeCalendar());
          } else {
            if (performances.length > 1) {
              dispatch(selectDate(performances[0].startDate));
            } else if (performances.length === 1) {
              dispatch(selectPerformance(performances[0]));
            }
          }
        })
        .then(() => {
          dispatch(fetchAllPerformancesSuccess());
        });
    });
  };
};

const fetchPerformancesStart = () => {
  return {
    type: FETCH_PERFORMANCES_START,
  };
};

const fetchPerformancesFail = (response) => {
  return {
    type: FETCH_PERFORMANCES_FAIL,
    response: response,
  };
};

const fetchPerformancesSuccess = (response) => {
  return {
    type: FETCH_PERFORMANCES_SUCCESS,
    response: response,
  };
};

const fetchAllPerformancesSuccess = () => {
  return {
    type: FETCH_ALL_PERFORMANCES_SUCCESS,
  };
};

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

export const updateUser = (userData) => {
  return (dispatch, getState) => {
    dispatch(updateUserStart());
    return Api.updateUser(userData, getState().auth).then(
      (response) => {
        if (response.ok) {
          return response
            .json()
            .then((json) => dispatch(updateUserSuccess(json)));
        } else {
          return response.json().then((json) => {
            dispatch(updateUserFail(json));
            return Promise.reject(new SubmissionError(parseApiError(json)));
          });
        }
      },
      (err) => {
        dispatch(updateUserFail(err));
        return Promise.reject(new SubmissionError(networkError));
      }
    );
  };
};

const updateUserStart = () => {
  return {
    type: UPDATE_USER_START,
  };
};

const updateUserFail = (response) => {
  return {
    type: UPDATE_USER_FAIL,
    response: response,
  };
};

const updateUserSuccess = (response) => {
  return {
    type: UPDATE_USER_SUCCESS,
    response: response,
  };
};

export const FETCH_USER_START = "FETCH_USER_START";
export const FETCH_USER_FAIL = "FETCH_USER_FAIL";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";

export const fetchUser = () => {
  return (dispatch, getState) => {
    dispatch(fetchUserStart());
    return Api.getUser(getState().auth).then(
      (response) => {
        if (response.ok) {
          return response
            .json()
            .then((json) => dispatch(fetchUserSuccess(json)));
        } else {
          return response.json().then((json) => {
            dispatch(fetchUserFail(json));
            return dispatch(logoutUser());
          });
        }
      },
      (err) => {
        dispatch(fetchUserFail(err));
        return dispatch(logoutUser());
      }
    );
  };
};

const fetchUserStart = () => {
  return {
    type: FETCH_USER_START,
  };
};

const fetchUserFail = (response) => {
  return {
    type: FETCH_USER_FAIL,
    response: response,
  };
};

const fetchUserSuccess = (response) => {
  return {
    type: FETCH_USER_SUCCESS,
    response: response,
  };
};

export const LOGIN_USER_START = "LOGIN_USER_START";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";

const loginUserStart = () => {
  return {
    type: LOGIN_USER_START,
  };
};

const loginUserFail = (response) => {
  return {
    type: LOGIN_USER_FAIL,
    response: response,
  };
};

const loginUserSuccess = (response) => {
  Cookies.set(
    process.env.REACT_APP_COOKIE_AUTH_KEY,
    JSON.stringify({
      authorization: response.data.accessToken,
      accessToken: response.data.accessToken,
      firstName: response.data.firstName,
      lastName: response.data.lastName,
      emailAddress: response.data.emailAddress,
    })
  );

  return {
    type: LOGIN_USER_SUCCESS,
    response: response,
  };
};

// This is a combination of loginUser + fetchCards
export const authenticateUser = (authData) => {
  return (dispatch, getState) => {
    dispatch(loginUserStart());
    return Api.authenticateUser(authData, getState().auth).then(
      (response) => {
        if (response.ok) {
          return response
            .json()
            .then((json) => dispatch(loginUserSuccess(json)));
        } else {
          return response.json().then((json) => {
            dispatch(loginUserFail(json));
            return Promise.reject(new SubmissionError(parseApiError(json)));
          });
        }
      },
      (err) => {
        dispatch(loginUserFail(err));
        return Promise.reject(new SubmissionError(networkError()));
      }
    );
  };
};

export const loginAndFetchCards = (authData) => {
  return (dispatch) => {
    return dispatch(authenticateUser(authData));
  };
};

export const LOGOUT_USER = "LOGOUT_USER";

export const logoutUser = () => {
  Cookies.expire(process.env.REACT_APP_COOKIE_AUTH_KEY);
  store.remove(process.env.REACT_APP_COOKIE_RESERVATION_KEY);
  return {
    type: LOGOUT_USER,
  };
};

export const showBasket = () => {
  return (dispatch, getState) => {
    const link = {
      pathname: "/basket",
      state: {
        modal: true,
        returnTo: "/",
      },
    };
    return dispatch(makeReservation()).then(
      (success) => dispatch(push(link)),
      (error) => Promise.resolve()
    );
  };
};

export const CREATE_SEATING_RESERVATION_TOKEN_SUCCESS =
  "CREATE_SEATING_RESERVATION_TOKEN_SUCCESS";

export const createSeatingReservationToken = (performanceId) => {
  // return (dispatch, getState) => {
  //   const { seatingChartsByPerformanceId } = getState()
  //   const seatingChart = seatingChartsByPerformanceId[performanceId]
  //   Api.createReservationToken(seatingChart.publicKey)
  //     .then(response => {
  //       if(response.ok){
  //         return response.json()
  //       }else{
  //         return Promise.reject()
  //       }
  //     })
  //     .then(json => dispatch(createSeatingReservationTokenSuccess(performanceId, json.reservationToken)))
  // }
};

export const createSeatingReservationTokenSuccess = (
  performanceId,
  reservationToken
) => {
  return {
    type: CREATE_SEATING_RESERVATION_TOKEN_SUCCESS,
    performanceId: performanceId,
    reservationToken,
  };
};

export const CREATE_RESERVATION_START = "CREATE_RESERVATION_START";
export const CREATE_RESERVATION_FAIL = "CREATE_RESERVATION_FAIL";
export const CREATE_RESERVATION_SUCCESS = "CREATE_RESERVATION_SUCCESS";
export const DELETE_RESERVATION = "DELETE_RESERVATION";

const makeReservation = () => {
  return (dispatch, getState) => {
    const state = getState();
    let basketItems = state.basket;
    let currentReservation = state.reservation;
    let { auth } = state;
    let total = getBasketTotal(state);
    dispatch(createReservationStart());
    let request = Api.createReservation(
      basketItems,
      auth,
      currentReservation.sessionId
    );

    return request
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response
            .json()
            .then((json) => Promise.reject(new CreateReservationError(json)));
        }
      })
      .then((json) => {
        store.set(
          process.env.REACT_APP_COOKIE_RESERVATION_KEY,
          json.data.reference
        );
        if ("parentIFrame" in window) {
          window.parentIFrame.sendMessage({
            messageType: "addToBasket",
            orderId: json.data.reference,
            orderTotal: total,
          });
        }
        return dispatch(createReservationSuccess(json, getState().basket.hash));
      })
      .catch((e) => {
        dispatch(createReservationFail(e.response));
        return Promise.reject(e);
      });
  };
};

export const createReservation = () => {
  return (dispatch, getState) => {
    let { settings, user, auth } = getState();

    return dispatch(makeReservation()).then(
      (success) => {
        if (!auth.loggedIn) {
          return dispatch(push("/register"));
        } else if (settings.includeAddress && !user.item.address) {
          return dispatch(push("/address"));
        } else if (settings.customFormId) {
          return dispatch(push(`/form/${settings.customFormId}`));
        } else {
          return dispatch(push("/confirm"));
        }
      },
      (error) => Promise.resolve()
    );
  };
};

const createReservationStart = () => {
  return {
    type: CREATE_RESERVATION_START,
  };
};

const createReservationFail = (response) => {
  return {
    type: CREATE_RESERVATION_FAIL,
    response: response,
  };
};

const createReservationSuccess = (response, basketHash) => {
  return {
    type: CREATE_RESERVATION_SUCCESS,
    response: response,
    basketHash: basketHash,
  };
};

const deleteReservation = () => {
  return {
    type: DELETE_RESERVATION,
  };
};

export const CREATE_ORDER_START = "CREATE_ORDER_START";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAIL = "CREATE_ORDER_FAIL";

export const createOrder = (paymentMethod) => {
  return (dispatch, getState) => {
    dispatch(createOrderStart());
    let { reservation, auth, donation, settings, basket, user, paymentSource } =
      getState();
    const feeExempt = !basket.includeFees;

    return Api.createOrder(
      reservation,
      auth,
      donation,
      settings.charityName,
      paymentMethod,
      user,
      feeExempt,
      paymentSource
    ).then(
      (response) => {
        if (response.ok) {
          return response.json().then((json) => {
            return dispatch(createOrderSuccess(json));
          });
        } else {
          return response.json().then((json) => {
            dispatch(createOrderFail(json));
            return Promise.reject(parseApiError(json));
          });
        }
      },
      (error) => {
        dispatch(createOrderFail(error));
        return Promise.reject(new SubmissionError(networkError()));
      }
    );
  };
};

const createOrderStart = () => {
  return {
    type: CREATE_ORDER_START,
  };
};

const createOrderSuccess = (response) => {
  return {
    type: CREATE_ORDER_SUCCESS,
    response: response,
  };
};

const createOrderFail = (error) => {
  return {
    type: CREATE_ORDER_FAIL,
    error: error._metadata.message,
  };
};

export const UPDATE_ORDER_START = "UPDATE_ORDER_START";
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";

export const updateOrder = (paymentIntentId) => {
  return (dispatch, getState) => {
    dispatch(updateOrderStart());
    let { order, auth } = getState();
    return Api.updateOrder(auth, order.item.id, paymentIntentId).then(
      (response) => {
        if (response.ok) {
          return response.json().then((json) => {
            return dispatch(updateOrderSuccess(json));
          });
        } else {
          return response.json().then((json) => {
            dispatch(updateOrderFail(json));
            return Promise.reject(new SubmissionError(parseApiError(json)));
          });
        }
      },
      (error) => {
        dispatch(updateOrderFail(error));
        return Promise.reject(new SubmissionError(networkError()));
      }
    );
  };
};

const updateOrderStart = () => {
  return {
    type: UPDATE_ORDER_START,
  };
};

const updateOrderSuccess = (response) => {
  return {
    type: UPDATE_ORDER_SUCCESS,
    response: response,
  };
};

const updateOrderFail = (error) => {
  return {
    type: UPDATE_ORDER_FAIL,
    error: error._metadata.message,
  };
};

export const CREATE_CARD_START = "CREATE_CARD_START";
export const CREATE_CARD_FAIL = "CREATE_CARD_FAIL";
export const CREATE_CARD_SUCCESS = "CREATE_CARD_SUCCESS";

export const createCard = (token) => {
  return (dispatch, getState) => {
    dispatch(createCardStart());
    return Api.createCard(token, getState().auth).then(
      (response) => {
        if (response.ok) {
          return response
            .json()
            .then((json) => dispatch(createCardSuccess(json)));
        } else {
          return response.json().then((json) => {
            dispatch(createCardFail(json));
            return Promise.reject(new SubmissionError(parseApiError(json)));
          });
        }
      },
      (err) => {
        dispatch(createCardFail(err));
        return Promise.reject(new SubmissionError(networkError));
      }
    );
  };
};

export const createCardStart = () => {
  return {
    type: CREATE_CARD_START,
  };
};

export const createCardFail = (error) => {
  return {
    type: CREATE_CARD_FAIL,
    error: error,
  };
};

const createCardSuccess = (response) => {
  return {
    type: CREATE_CARD_SUCCESS,
    response: response,
  };
};

export const CREATE_CARD_AND_CHECKOUT_START = "CREATE_CARD_AND_CHECKOUT";
export const CREATE_CARD_AND_CHECKOUT_SUCCESS =
  "CREATE_CARD_AND_CHECKOUT_SUCCESS";
export const CREATE_CARD_AND_CHECKOUT_FAIL = "CREATE_CARD_AND_CHECKOUT_FAIL";

export const createCardAndCheckout = (token) => {
  return (dispatch) => {
    return dispatch(createOrder(token));
  };
};

export const EXPIRED_RESERVATION_START = "EXPIRED_RESERVATION_START";

export const expiredReservation = () => {
  return (dispatch, getState) => {
    // redirect to the expired page
    dispatch(push("/expired"));
    dispatch(expiredReservationStart());
    store.remove(process.env.REACT_APP_COOKIE_RESERVATION_KEY);
  };
};

const expiredReservationStart = () => {
  return {
    type: EXPIRED_RESERVATION_START,
  };
};

export const SEND_FORGOT_PASSWORD_EMAIL_START =
  "SEND_FORGOT_PASSWORD_EMAIL_START";
export const SEND_FORGOT_PASSWORD_EMAIL_SUCCESS =
  "SEND_FORGOT_PASSWORD_EMAIL_SUCCESS";
export const SEND_FORGOT_PASSWORD_EMAIL_FAIL =
  "SEND_FORGOT_PASSWORD_EMAIL_FAIL";

export const sendForgotPasswordEmail = (email) => {
  return (dispatch, getState) => {
    dispatch(sendForgotPasswordEmailStart());
    return Api.sendForgotPasswordEmail(email, getState().auth).then(
      (response) => {
        if (response.ok) {
          return response
            .json()
            .then((json) => dispatch(sendForgotPasswordEmailSuccess(json)));
        } else {
          return response.json().then((json) => {
            dispatch(sendForgotPasswordEmailFail(json));
            return Promise.reject(new SubmissionError(parseApiError(json)));
          });
        }
      },
      (err) => {
        dispatch(sendForgotPasswordEmailFail(err));
        return Promise.reject(new SubmissionError(networkError()));
      }
    );
  };
};

const sendForgotPasswordEmailStart = () => {
  return {
    type: SEND_FORGOT_PASSWORD_EMAIL_START,
  };
};

const sendForgotPasswordEmailSuccess = (response) => {
  return {
    type: SEND_FORGOT_PASSWORD_EMAIL_SUCCESS,
    response: response,
  };
};

const sendForgotPasswordEmailFail = (error) => {
  return {
    type: SEND_FORGOT_PASSWORD_EMAIL_FAIL,
    error: error,
  };
};

export const deleteLineItem = (item) => {
  return (dispatch, getState) => {
    dispatch(basketRemoveLineItem(item));
    let state = getState();
    if (getBasketItemCount(state) === 0) {
      dispatch(deleteReservation());
      let link = {
        pathname: "/",
        state: {
          modal: false,
        },
      };
      return dispatch(push(link));
    } else {
      return dispatch(makeReservation());
    }
  };
};

export const BASKET_REMOVE_LINE_ITEM = "BASKET_REMOVE_TICKET_LINE_ITEM";

export const basketRemoveLineItem = (item) => {
  return {
    type: BASKET_REMOVE_LINE_ITEM,
    item: item,
  };
};

export const APPLY_DISCOUNT_CODE_START = "APPLY_DISCOUNT_CODE_START";
export const APPLY_DISCOUNT_CODE_SUCCESS = "APPLY_DISCOUNT_CODE_SUCCESS";
export const APPLY_DISCOUNT_CODE_FAIL = "APPLY_DISCOUNT_CODE_FAIL";

export const applyDiscountCode = (discountCode) => {
  return (dispatch, getState) => {
    dispatch(applyDiscountCodeStart());
    let auth = getState().auth;
    let reservationRef = getState().reservation.item.reference;

    return Api.updateReservation(reservationRef, discountCode, auth).then(
      (response) => {
        if (response.ok) {
          return response.json().then((json) => {
            dispatch(applyDiscountCodeSuccess(json));
          });
        } else {
          return response.json().then((json) => {
            dispatch(applyDiscountCodeFail(json));
            return Promise.reject(new SubmissionError(parseApiError(json)));
          });
        }
      },
      (err) => {
        dispatch(applyDiscountCodeFail(err));
        return Promise.reject(new SubmissionError(parseApiError(err)));
      }
    );
  };
};

const applyDiscountCodeStart = () => {
  return {
    type: APPLY_DISCOUNT_CODE_START,
  };
};

const applyDiscountCodeSuccess = (response) => {
  return {
    type: APPLY_DISCOUNT_CODE_SUCCESS,
    response: response,
  };
};

const applyDiscountCodeFail = (response) => {
  return {
    type: APPLY_DISCOUNT_CODE_FAIL,
    response: response,
  };
};

export const OPEN_CALENDAR = "OPEN_CALENDAR";
export const CLOSE_CALENDAR = "CLOSE_CALENDAR";

export const openCalendar = () => {
  return {
    type: OPEN_CALENDAR,
  };
};

export const closeCalendar = () => {
  return {
    type: CLOSE_CALENDAR,
  };
};

export const FETCH_CUSTOMERS_START = "FETCH_CUSTOMERS_START";
export const FETCH_CUSTOMERS_FAIL = "FETCH_CUSTOMERS_FAIL";
export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";

export const fetchCustomers = () => {
  return (dispatch, getState) => {
    dispatch(fetchCustomersStart());

    let request = Api.getCustomers(getState().auth);
    return request
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response
            .json()
            .then((err) => Promise.reject(new FetchCustomersError(err)));
        }
      })
      .then((json) => dispatch(fetchCustomersSuccess(json)))
      .catch((err) => dispatch(fetchCustomersFail(err.response)));
  };
};

const fetchCustomersStart = () => {
  return {
    type: FETCH_CUSTOMERS_START,
  };
};

const fetchCustomersFail = (response) => {
  return {
    type: FETCH_CUSTOMERS_FAIL,
    response: response,
  };
};

const fetchCustomersSuccess = (response) => {
  return {
    type: FETCH_CUSTOMERS_SUCCESS,
    response: response,
  };
};

export const SELECT_CUSTOMER = "SELECT_CUSTOMER";
export const CLEAR_CUSTOMER_DETAILS = "CLEAR_CUSTOMER_DETAILS";
export const RESET_DATA = "RESET_DATA";

export const selectCustomer = (customer) => {
  return {
    type: SELECT_CUSTOMER,
    customer: customer,
  };
};

export const clearCustomerDetails = () => {
  return {
    type: CLEAR_CUSTOMER_DETAILS,
  };
};

export const resetData = () => {
  return {
    type: RESET_DATA,
  };
};

export const SELECT_PAYMENT_SOURCE = "SELECT_PAYMENT_SOURCE";

export const selectPaymentSource = (paymentSource) => {
  return {
    type: SELECT_PAYMENT_SOURCE,
    paymentSource: paymentSource,
  };
};

export const TOGGLE_CUSTOMER_DETAILS = "TOGGLE_CUSTOMER_DETAILS";

export const toggleShowCustomerDetails = () => {
  return {
    type: TOGGLE_CUSTOMER_DETAILS,
  };
};

export const SEATING_CHART_LOADED = "SEATING_CHART_LOADED";

export const seatingChartLoaded = (performanceId) => {
  return {
    type: SEATING_CHART_LOADED,
    performanceId,
  };
};

export const REQUEST_SEASON_TICKETS = "REQUEST_SEASON_TICKETS";
export const RECEIVE_SEASON_TICKETS = "RECEIVE_SEASON_TICKETS";
export const REQUEST_SEASON_TICKETS_FAIL = "REQUEST_SEASON_TICKETS_FAIL";

const fetchSeasonTickets = (performanceId, state) => {
  return (dispatch) => {
    dispatch(requestSeasonTickets(performanceId));
    let request = Api.fetchSeasonTicketsForPerformance(
      performanceId,
      state.auth
    );
    return request
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response
            .json()
            .then((errResponse) => Promise.reject(errResponse));
        }
      })
      .then((json) => dispatch(receiveSeasonTickets(performanceId, json)))
      .catch((errResponse) =>
        dispatch(requestSeasonTicketsFailed(performanceId, errResponse))
      );
  };
};

const shouldFetchSeasonTickets = (state, performanceId) => {
  const seasonTickets = state.seasonTicketsByPerformance[performanceId];
  if (!seasonTickets) {
    return true;
  }
  if (seasonTickets.isFetching) {
    return false;
  }
  return seasonTickets.didInvalidate;
};

export function fetchSeasonTicketsIfNeeded(performanceId) {
  return (dispatch, getState) => {
    if (shouldFetchSeasonTickets(getState(), performanceId)) {
      return dispatch(fetchSeasonTickets(performanceId, getState()));
    }
  };
}

const requestSeasonTickets = (performanceId) => ({
  type: REQUEST_SEASON_TICKETS,
  performanceId,
});

const receiveSeasonTickets = (performanceId, json) => ({
  type: RECEIVE_SEASON_TICKETS,
  performanceId,
  response: json,
  receivedAt: Date.now(),
});

const requestSeasonTicketsFailed = (performanceId, json) => ({
  type: REQUEST_SEASON_TICKETS_FAIL,
  performanceId,
  response: json,
});

export const BASKET_ADD_TICKET = "BASKET_ADD_TICKET";
export const BASKET_ADD_SEASON_TICKET = "BASKET_ADD_SEASON_TICKET";
export const BASKET_ADD_PRODUCT = "BASKET_ADD_PRODUCT";

export const BASKET_REMOVE_TICKET = "BASKET_REMOVE_TICKET";
export const BASKET_REMOVE_SEASON_TICKET = "BASKET_REMOVE_SEASON_TICKET";
export const BASKET_REMOVE_PRODUCT = "BASKET_REMOVE_PRODUCT";

// increment quantity of a ticket in the basket
export const basketAddTicket = (event, performance, ticket, quantity, seat) => {
  return {
    type: BASKET_ADD_TICKET,
    event: event,
    performance: performance,
    ticket: ticket,
    quantity: quantity,
    seat: seat,
  };
};

// increment quantity of a ticket in the basket
export const basketAddSeasonTicket = (event, performance, ticket, quantity) => {
  return {
    type: BASKET_ADD_SEASON_TICKET,
    event: event,
    performance: performance,
    ticket: ticket,
    quantity: quantity,
  };
};

export const basketAddProduct = (item, quantity) => {
  return {
    type: BASKET_ADD_PRODUCT,
    ticket: item,
    quantity: quantity,
  };
};

// decrement quantity of a ticket in basket
export const basketRemoveTicket = (
  event,
  performance,
  ticket,
  quantity,
  seat
) => {
  return {
    type: BASKET_REMOVE_TICKET,
    event: event,
    performance: performance,
    ticket: ticket,
    quantity: quantity,
    seat: seat,
  };
};

// completely remove a ticket from the basket
export const basketRemoveSeasonTicket = (
  event,
  performance,
  ticket,
  quantity
) => {
  return {
    type: BASKET_REMOVE_SEASON_TICKET,
    event: event,
    performance: performance,
    ticket: ticket,
    quantity: quantity,
  };
};

export const basketRemoveProduct = (item, quantity) => {
  return {
    type: BASKET_REMOVE_PRODUCT,
    ticket: item,
    quantity: quantity,
  };
};

export const UPDATE_RESERVATION = "UPDATE_RESERVATION";
export const UPDATE_RESERVATION_START = "UPDATE_RESERVATION_START";
export const UPDATE_RESERVATION_SUCCESS = "UPDATE_RESERVATION_SUCCESS";
export const UPDATE_RESERVATION_FAILURE = "UPDATE_RESERVATION_FAILURE";

export const updateReservation = (reservationReference) => {
  return (dispatch, getState) => {
    dispatch(updateReservationStart(reservationReference));
    let request = Api.refreshReservation(reservationReference, getState().auth);
    return request
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response
            .json()
            .then((errResponse) => Promise.reject(errResponse));
        }
      })
      .then((json) => {
        const reservationItems = json.data.ticketReservations;
        reservationItems.forEach((item) => {
          dispatch(
            basketAddTicket(
              item.event,
              item.performance,
              item.ticket,
              item.quantity,
              item.seats
            )
          );
        });
        dispatch(updateReservationSuccess(reservationReference, json));
      })
      .catch((errResponse) =>
        dispatch(updateReservationFailure(reservationReference, errResponse))
      );
  };
};

const updateReservationStart = (reservationReference) => ({
  type: UPDATE_RESERVATION_START,
  reservationReference,
});

const updateReservationSuccess = (reservationReference, json) => ({
  type: UPDATE_RESERVATION_SUCCESS,
  reservationReference,
  response: json,
});

const updateReservationFailure = (reservationReference, json) => ({
  type: UPDATE_RESERVATION_FAILURE,
  reservationReference,
  response: json,
});

export const REDIRECT_START = "REDIRECT_START";

const redirectStart = () => ({
  type: REDIRECT_START,
});

export const showListings = (shouldReserve) => {
  return (dispatch, getState) => {
    dispatch(redirectStart());
    const listingsLink = getState().settings.listingsLink;
    if (shouldReserve) {
      return dispatch(makeReservation())
        .then((json) => {
          store.set(
            process.env.REACT_APP_COOKIE_RESERVATION_KEY,
            json.data.reference
          );
          window.top.location.href = listingsLink;
        })
        .catch((e) => {
          window.top.location.href = listingsLink;
        });
    }
    window.top.location.href = listingsLink;
  };
};

export const FETCH_EVENT_SETTINGS_START = "FETCH_EVENT_SETTINGS_START";
export const FETCH_EVENT_SETTINGS_SUCCESS = "FETCH_EVENT_SETTINGS_SUCCESS";
export const FETCH_EVENT_SETTINGS_FAIL = "FETCH_EVENT_SETTINGS_FAIL";

const fetchEventSettingsStart = () => {
  return {
    type: FETCH_EVENT_SETTINGS_START,
  };
};

const fetchEventSettingsFail = (response) => {
  return {
    type: FETCH_EVENT_SETTINGS_FAIL,
    response: response,
  };
};

const fetchEventSettingsSuccess = (response) => {
  return {
    type: FETCH_EVENT_SETTINGS_SUCCESS,
    response: response,
  };
};

export const fetchEventSettings = (eventId) => {
  return (dispatch, getState) => {
    dispatch(fetchEventSettingsStart());

    return Api.getEventSettings(eventId, getState().auth).then(
      (response) => {
        if (response.ok) {
          return response
            .json()
            .then((json) => dispatch(fetchEventSettingsSuccess(json)));
        } else {
          return response
            .json()
            .then((err) => dispatch(fetchEventSettingsFail(err)));
        }
      },
      (err) => dispatch(fetchEventSettingsFail(err))
    );
  };
};

export const CREATE_USER_START = "CREATE_USER_START";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";

export const createUser = (userData) => {
  return (dispatch, getState) => {
    dispatch(createUserStart());
    return Api.createUser(userData, getState().auth).then(
      (response) => {
        if (response.ok) {
          return response
            .json()
            .then((json) => dispatch(createUserSuccess(json)));
        } else {
          return response.json().then((json) => {
            dispatch(createUserFail(json));
            return Promise.reject(parseApiError(json));
          });
        }
      },
      (err) => {
        dispatch(createUserFail(err));
        return Promise.reject();
      }
    );
  };
};

const createUserStart = () => {
  return {
    type: CREATE_USER_START,
  };
};

const createUserFail = (response) => {
  return {
    type: CREATE_USER_FAIL,
    response: response,
  };
};

const createUserSuccess = (response) => {
  Cookies.set(
    process.env.REACT_APP_COOKIE_AUTH_KEY,
    JSON.stringify({
      authorization: response.data.accessToken,
      accessToken: response.data.accessToken,
      firstName: response.data.firstName,
      lastName: response.data.lastName,
      emailAddress: response.data.emailAddress,
    })
  );

  return {
    type: CREATE_USER_SUCCESS,
    response: response,
  };
};

export const BOOK_NOW_CLICK = "BOOK_NOW_CLICK";
export const bookNowClick = () => ({
  type: BOOK_NOW_CLICK,
});

export const ADD_DONATION = "ADD_DONATION";
export const REMOVE_DONATION = "REMOVE_DONATION";

export const addDonation = (amount) => ({
  type: ADD_DONATION,
  amount: amount,
});

export const removeDonation = () => ({
  type: REMOVE_DONATION,
});

export const SHOW_OTHER_DONATION = "SHOW_OTHER_DONATION";
export const HIDE_OTHER_DONATION = "HIDE_OTHER_DONATION";

export const showOtherDonationForm = () => ({
  type: SHOW_OTHER_DONATION,
});

export const hideOtherDonationForm = () => ({
  type: HIDE_OTHER_DONATION,
});

export const ADD_GIFT_AID = "ADD_GIFT_AID";
export const REMOVE_GIFT_AID = "REMOVE_GIFT_AID";

export const addGiftAid = () => ({
  type: ADD_GIFT_AID,
});

export const removeGiftAid = () => ({
  type: REMOVE_GIFT_AID,
});

export const FETCH_FORM_START = "FETCH_FORM_START";
export const FETCH_FORM_SUCCESS = "FETCH_FORM_SUCCESS";
export const FETCH_FORM_FAIL = "FETCH_FORM_FAIL";

const fetchFormStart = () => {
  return {
    type: FETCH_FORM_START,
  };
};

const fetchFormFail = (response) => {
  return {
    type: FETCH_FORM_FAIL,
    response: response,
  };
};

const fetchFormSuccess = (response) => {
  return {
    type: FETCH_FORM_SUCCESS,
    response: response,
  };
};

export const fetchForm = (formId) => {
  return (dispatch, getState) => {
    dispatch(fetchFormStart());
    return Api.getForm(formId, getState().auth).then(
      (response) => {
        if (response.ok) {
          return response
            .json()
            .then((json) => dispatch(fetchFormSuccess(json)));
        } else {
          return response.json().then((err) => dispatch(fetchFormFail(err)));
        }
      },
      (err) => dispatch(fetchFormFail(err))
    );
  };
};

export const UPDATE_FORM_START = "UPDATE_FORM_START";
export const UPDATE_FORM_FAIL = "UPDATE_FORM_FAIL";
export const UPDATE_FORM_SUCCESS = "UPDATE_FORM_SUCCESS";

export const updateCustomForm = (formId, formData) => {
  return (dispatch, getState) => {
    dispatch(updateFormStart());
    let reservationRef = getState().reservation.item.reference;
    return Api.updateForm(
      reservationRef,
      formId,
      formData,
      getState().auth
    ).then(
      (response) => {
        if (response.ok) {
          return response
            .json()
            .then((json) => dispatch(updateFormSuccess(json)));
        } else {
          return response.json().then((json) => {
            dispatch(updateFormFail(json));
            return Promise.reject(new SubmissionError(parseApiError(json)));
          });
        }
      },
      (err) => {
        dispatch(updateFormFail(err));
        return Promise.reject(new SubmissionError(networkError));
      }
    );
  };
};

const updateFormStart = () => {
  return {
    type: UPDATE_FORM_START,
  };
};

const updateFormFail = (response) => {
  return {
    type: UPDATE_FORM_FAIL,
    response: response,
  };
};

const updateFormSuccess = (response) => {
  return {
    type: UPDATE_FORM_SUCCESS,
    response: response,
  };
};

export const JOIN_WAITING_LIST_START = "JOIN_WAITING_LIST_START";
export const JOIN_WAITING_LIST_SUCCESS = "JOIN_WAITING_LIST_SUCCESS";
export const JOIN_WAITING_LIST_FAIL = "JOIN_WAITING_LIST_FAIL";

export const joinWaitingList = (email) => {
  return (dispatch, getState) => {
    dispatch(joinWaitingListStart());
    const { auth, selectedPerformance } = getState();
    return Api.joinWaitingList(email, selectedPerformance.id, auth).then(
      (response) => {
        if (response.ok) {
          return response
            .json()
            .then((json) => dispatch(joinWaitingListSuccess(json)));
        } else {
          return response.json().then((json) => {
            dispatch(joinWaitingListFail(json));
            return Promise.reject(new SubmissionError(parseApiError(json)));
          });
        }
      },
      (err) => {
        dispatch(joinWaitingListFail(err));
        return Promise.reject(new SubmissionError(networkError()));
      }
    );
  };
};

const joinWaitingListStart = () => {
  return {
    type: JOIN_WAITING_LIST_START,
  };
};

const joinWaitingListSuccess = (response) => {
  return {
    type: JOIN_WAITING_LIST_SUCCESS,
    response: response,
  };
};

const joinWaitingListFail = (error) => {
  return {
    type: JOIN_WAITING_LIST_FAIL,
    error: error,
  };
};

export const FETCH_PAST_ORDERS_START = "FETCH_PAST_ORDERS_START";
export const FETCH_PAST_ORDERS_SUCCESS = "FETCH_PAST_ORDERS_SUCCESS";
export const FETCH_PAST_ORDERS_FAIL = "FETCH_PAST_ORDERS_FAIL";

export const fetchPastOrders = (page) => {
  return (dispatch, getState) => {
    dispatch(fetchPastOrdersStart(page));
    const { auth } = getState();
    return Api.fetchPastOrders(page, auth).then(
      (response) => {
        if (response.ok) {
          return response
            .json()
            .then((json) => dispatch(fetchPastOrdersSuccess(page, json)));
        } else {
          return response.json().then((json) => {
            dispatch(fetchPastOrdersFail(page, json));
          });
        }
      },
      (err) => {
        dispatch(fetchPastOrdersFail(page, err));
      }
    );
  };
};

const fetchPastOrdersStart = (page) => {
  return {
    type: FETCH_PAST_ORDERS_START,
    page: page,
  };
};

const fetchPastOrdersSuccess = (page, response) => {
  return {
    type: FETCH_PAST_ORDERS_SUCCESS,
    page: page,
    response: response,
  };
};

const fetchPastOrdersFail = (page, error) => {
  return {
    type: FETCH_PAST_ORDERS_FAIL,
    page: page,
    error: error,
  };
};

export const SELECT_PAST_ORDER = "SELECT_PAST_ORDER";
export const CANCEL_SELECT_ORDER = "CANCEL_SELECT_ORDER";

export const selectPastOrder = (order) => {
  return {
    type: SELECT_PAST_ORDER,
    order: order,
  };
};

export const cancelSelectOrder = () => {
  return {
    type: CANCEL_SELECT_ORDER,
  };
};

export const CANCEL_TICKET_START = "CANCEL_TICKET_START";
export const CANCEL_TICKET_SUCCESS = "CANCEL_TICKET_SUCCESS";
export const CANCEL_TICKET_FAIL = "CANCEL_TICKET_FAIL";

const cancelTicketStart = (receiptId) => {
  return {
    type: CANCEL_TICKET_START,
    receiptId: receiptId,
  };
};

const cancelTicketSuccess = (receiptId, response) => {
  return {
    type: CANCEL_TICKET_SUCCESS,
    response: response,
    receiptId: receiptId,
  };
};

const cancelTicketFail = (receiptId, error) => {
  return {
    type: CANCEL_TICKET_FAIL,
    receiptId: receiptId,
    error: error,
  };
};

export const cancelTicket = (receiptId) => {
  return (dispatch, getState) => {
    dispatch(cancelTicketStart(receiptId));
    const { auth } = getState();
    return Api.cancelTicket(receiptId, auth).then(
      (response) => {
        if (response.ok) {
          return response
            .json()
            .then((json) => dispatch(cancelTicketSuccess(receiptId, json)));
        } else {
          return response.json().then((json) => {
            dispatch(cancelTicketFail(receiptId, json));
          });
        }
      },
      (err) => {
        dispatch(cancelTicketFail(err));
      }
    );
  };
};

export const SET_BASKET_VALID = "SET_BASKET_VALID";
export const SET_BASKET_INVALID = "SET_BASKET_INVALID";

export const setBasketValid = () => {
  return {
    type: SET_BASKET_VALID,
  };
};
export const setBasketInvalid = () => {
  return {
    type: SET_BASKET_INVALID,
  };
};

export const REQUEST_PRODUCTS = "REQUEST_PRODUCTS";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const REQUEST_PRODUCTS_FAIL = "REQUEST_PRODUCTS_FAIL";
export const INVALIDATE_COLLECTION = "INVALIDATE_COLLECTION";

const requestProducts = (collectionId) => ({
  type: REQUEST_PRODUCTS,
  collectionId,
});

const receiveProducts = (collectionId, json) => ({
  type: RECEIVE_PRODUCTS,
  collectionId,
  response: json,
  receivedAt: Date.now(),
});

const requestProductsFailed = (collectionId, json) => ({
  type: REQUEST_PRODUCTS_FAIL,
  collectionId,
  response: json,
});

const fetchProducts = (collectionId, state) => {
  return (dispatch) => {
    dispatch(requestProducts(collectionId));
    let request = Api.fetchProductsForCollection(collectionId, state.auth);
    return request
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response
            .json()
            .then((errResponse) => Promise.reject(errResponse));
        }
      })
      .then((json) => dispatch(receiveProducts(collectionId, json)))
      .catch((errResponse) =>
        dispatch(requestProductsFailed(collectionId, errResponse))
      );
  };
};

const shouldFetchProducts = (state, collectionId) => {
  const products = state.productsByCollection[collectionId];
  if (!products) {
    return true;
  }
  if (products.isFetching) {
    return false;
  }
  return true;
};

export function fetchProductsIfNeeded(collectionId) {
  return (dispatch, getState) => {
    if (shouldFetchProducts(getState(), collectionId)) {
      return dispatch(fetchProducts(collectionId, getState()));
    }
  };
}

export const FETCH_COLLECTION_START = "FETCH_COLLECTION_START";
export const FETCH_COLLECTION_SUCCESS = "FETCH_COLLECTION_SUCCESS";
export const FETCH_COLLECTION_FAIL = "FETCH_COLLECTION_FAIL";

const fetchCollectionStart = () => {
  return {
    type: FETCH_COLLECTION_START,
  };
};

const fetchCollectionFail = (response) => {
  return {
    type: FETCH_COLLECTION_FAIL,
    response: response,
  };
};

const fetchCollectionSuccess = (response) => {
  return {
    type: FETCH_COLLECTION_SUCCESS,
    response: response,
  };
};

export const fetchCollection = (collectionId) => {
  return (dispatch, getState) => {
    dispatch(fetchCollectionStart());

    let request = Api.getCollection(collectionId, getState().auth);

    request
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((err) => Promise.reject(err));
        }
      })
      .then((json) => dispatch(fetchCollectionSuccess(json)))
      .catch((err) => dispatch(fetchCollectionFail(err)));

    return request;
  };
};

export const FETCH_PRODUCT_START = "FETCH_PRODUCT_START";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAIL = "FETCH_PRODUCT_FAIL";

export const fetchProduct = (productId) => {
  return (dispatch, getState) => {
    dispatch(fetchProductStart());

    let request = Api.getProduct(productId, getState().auth);

    request
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((err) => Promise.reject(err));
        }
      })
      .then((json) => dispatch(fetchProductSuccess(json)))
      .catch((err) => dispatch(fetchProductFail(err)));

    return request;
  };
};

const fetchProductStart = () => {
  return {
    type: FETCH_PRODUCT_START,
  };
};

const fetchProductFail = (response) => {
  return {
    type: FETCH_PRODUCT_FAIL,
    response: response,
  };
};

const fetchProductSuccess = (response) => {
  return {
    type: FETCH_PRODUCT_SUCCESS,
    response: response,
  };
};

export const REMOVE_BOOKING_FEES = "REMOVE_BOOKING_FEES";

export const removeBookingFees = () => {
  return {
    type: REMOVE_BOOKING_FEES,
  };
};
