import React from "react";
import PropTypes from "prop-types";
import TicketRow from "components/TicketRow";
import styles from "./TicketTable.module.css";

export default class TicketTable extends React.Component {
  static propTypes = {
    tickets: PropTypes.array,
    performance: PropTypes.object.isRequired,
    basket: PropTypes.object.isRequired,
    onIncrementClick: PropTypes.func.isRequired,
    onDecrementClick: PropTypes.func.isRequired,
    capacity: PropTypes.object.isRequired,
  };

  render = () => {
    if (!this.props.tickets || this.props.tickets.length === 0) {
      return (
        <div className={styles.noTickets}>
          <h3>There are no tickets available to purchase on this date.</h3>
        </div>
      );
    }

    const priceSort = (a, b) => {
      if (a.index && b.index) {
        return a.index > b.index ? 1 : -1;
      }
      if (a.total === b.total) {
        return a.id > b.id ? 1 : -1;
      }
      return a.total < b.total ? 1 : -1;
    };
    let tickets = this.props.tickets.slice();
    tickets.sort(priceSort);

    let ticketRowNodes = tickets.map((ticket) => {
      let basketItem = this.props.basket[ticket.id];
      let quantity = basketItem ? basketItem.quantity : 0;
      let capGroupRef = null;

      // use cap group in global state, rather than ticket.capacity,
      // so that different tickets will reference the same object rather
      // than seperate copies
      if (ticket.capacity) {
        capGroupRef = this.props.capacity[ticket.capacity.id];
      }

      return (
        <TicketRow
          quantity={quantity}
          key={ticket.id}
          ticket={ticket}
          performance={this.props.performance}
          capacity={capGroupRef}
          lowInventoryLevel={this.props.lowInventoryLevel}
          onIncrementClick={this.props.onIncrementClick}
          onDecrementClick={this.props.onDecrementClick}
        />
      );
    });

    return (
      <div className={styles.container}>
        <table className={styles.ticketTable}>
          <thead>
            <tr>
              <td className={styles.headCell}>Ticket Name</td>
              <td className={styles.headCell}>Type</td>
              <td className={styles.headCell}>Price</td>
              <td className={styles.headCell}></td>
            </tr>
          </thead>
          <tbody>{ticketRowNodes}</tbody>
        </table>
      </div>
    );
  };
}
