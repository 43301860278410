import React from "react";
import PropTypes from "prop-types";
import styles from "./InputErrorMessage.module.css";

export default class InputErrorMessage extends React.Component {
  static propTypes = {
    message: PropTypes.string.isRequired,
  };

  render = () => {
    return <span className={styles.root}>{this.props.message}</span>;
  };
}
