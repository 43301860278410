import React from "react";
import InputErrorMessage from "components/InputErrorMessage";

// TODO: the renderError functions could be combined if the structure of the
// error response from the server, and the results from the client side
// validator are the same.

/**
 * This renders client side errors from the validator
 *
 * @param {string} fieldName The name of field we want to render errors for
 * @param {object} validationResult The result from the validation
 */
export const renderErrorForField = (fieldName, validationResult) => {
  if (!validationResult) {
    return;
  }
  for (let fieldItem of validationResult.errors) {
    if (fieldItem.path === fieldName) {
      return <InputErrorMessage message={fieldItem.message} />;
    }
  }
};

/**
 * This renders errors returned from the server for forms
 *
 * @param {string} fieldName The name of the field
 * @param {object} errorResponse The full json response object
 */
export const renderServerErrorForField = (fieldName, errorResponse) => {
  if (!errorResponse) {
    return;
  }
  for (let errorItem of errorResponse._metadata.errors) {
    if (errorItem.attribute === fieldName) {
      return <InputErrorMessage message={errorItem.messages} />;
    }
  }
};
