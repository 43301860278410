import React from "react";
import styles from "./PlaceholderShim.module.css";

// Shim for IE
export class ShimmedInput extends React.Component {
  componentWillMount() {
    this.placeholderText = this.props.placeholder;
    this.realValue = this.props.value;
    this.originalType = this.props.type;

    this.isPlaceholding = this.realValue ? false : true;
  }

  /**
   * Handle focus event
   *
   */
  handleOnFocus = (evt) => {
    this.setSelection(evt.target);

    this.isPlaceholding = this.realValue ? false : true;
    this.hasFocus = true;

    if (this.props.onFocus) {
      this.props.onFocus(evt);
    }
  };

  /**
   * Handle blur (when component loses focus)
   *
   */
  handleOnBlur = (evt) => {
    this.hasFocus = false;

    if (this.props.onBlur) {
      this.props.onBlur(evt);
    }
  };

  /**
   * When value in the component changes
   *
   */
  handleOnChange = (evt) => {
    if (this.isPlaceholding) {
      let valueWithPlaceholder = evt.target.value;
      let index = valueWithPlaceholder.indexOf(this.placeholderText);

      if (index !== -1) {
        this.realValue = valueWithPlaceholder.slice(0, index);
      }

      this.isPlaceholding = false;
      this.forceUpdate();
    } else {
      this.realValue = evt.target.value;

      if (!this.realValue) {
        this.isPlaceholding = true;
        this.forceUpdate();
      }
    }

    if (this.props.onChange) {
      this.props.onChange(evt);
    }
  };

  /**
   * Called when text is selected
   *
   */
  handleOnSelect = (evt) => {
    evt.preventDefault();

    this.setSelection(evt.target);

    if (this.props.onSelect) {
      return this.props.onSelect(evt);
    }
  };

  /**
   * This is the text that will be rendered in the input
   *
   */
  getDisplayValue = () => {
    if (this.isPlaceholding) {
      return this.placeholderText;
    } else {
      return this.realValue;
    }
  };

  /**
   * When clicked on, we move the cursor to the start of the input field
   *
   */
  setSelection = (node) => {
    if (
      this.isPlaceholding &&
      "setSelectionRange" in node &&
      (node.selectionStart !== 0 || node.selectionEnd !== 0)
    ) {
      node.setSelectionRange(0, 0);
    }
  };

  /**
   * Add in placeholder class if we're placeholding
   *
   */
  getShimClassName = () => {
    let className = this.props.className;
    if (this.isPlaceholding) {
      className += " " + styles.placeholder;
    }

    return className;
  };

  render() {
    // Override the event listeners on the actual input with our own
    const inputProps = Object.assign({}, this.props, {
      onFocus: this.handleOnFocus,
      onBlur: this.handleOnBlur,
      onChange: this.handleOnChange,
      onSelect: this.handleOnSelect,
      value: this.getDisplayValue(),
      className: this.getShimClassName(),
    });

    // If it's a password field, swap out with a text field when we're in
    // placeholding mode
    if (this.originalType === "password" && this.isPlaceholding) {
      inputProps.type = "text";
      return <input {...inputProps} />;
    }

    return <input {...inputProps} />;
  }
}

// Pass through to normal inputs for browsers which support placeholder
export class NativeInput extends React.Component {
  render() {
    return <input {...this.props} />;
  }
}

const nativeSupport =
  typeof document !== "undefined" &&
  "placeholder" in document.createElement("input");

export const getPlaceholderShim = () => {
  if (nativeSupport) {
    return NativeInput;
  } else {
    return ShimmedInput;
  }
};

export default getPlaceholderShim();
