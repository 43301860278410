import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LaddaButton from "components/LaddaButton";
import {
  createUser,
  toggleShowCustomerDetails,
  selectPaymentSource,
  selectCustomer,
  clearCustomerDetails,
} from "actions";
import styles from "./Register.module.css";
import { push } from "connected-react-router";
import Select from "react-select";
import { validateUser, validateUserNotEmpty } from "utils/Validation";
import { getIsPaymentRequired } from "selectors";
import UserForm from "components/UserForm";

let paymentOptions = [
  { value: "stripe", label: "Online Payment" },
  { value: "cash", label: "OTD Cash Payment" },
  { value: "card", label: "OTD Card Payment" },
  { value: "invoice", label: "Payment by Invoice" },
  { value: "voucher", label: "Voucher" },
];
class RegisterView extends React.Component {
  state = {
    user: {
      firstName: "",
      lastName: "",
      email: "",
      optIn: false,
    },
    userDataValid: false,
    buttonClicked: false,
    userValidation: null,
  };

  componentDidMount() {
    if (this.props.userSelected) {
      let newUserData = {
        firstName: this.props.user.firstName,
        lastName: this.props.user.lastName,
        email: this.props.user.emailAddress,
        optIn: this.props.user.optIn ? this.props.user.optIn : false,
      };
      this.setState({
        userDataValid: true,
        user: newUserData,
      });
    }
  }

  handleUserDataChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    let newUserData = Object.assign({}, this.state.user, {
      [name]: value,
    });
    let userValidation = validateUserNotEmpty(newUserData);

    this.setState({
      userDataValid: userValidation.ok,
      user: newUserData,
    });
  };

  handleAutoSuggestDataChange = (event, { newValue, method }) => {
    let newUserData = Object.assign({}, this.state.user, {
      email: newValue,
    });

    let userValidation = validateUserNotEmpty(newUserData);

    this.setState({
      userDataValid: userValidation.ok,
      user: newUserData,
    });
  };

  handleSelectCustomer = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    const selectedCustomer = suggestion;
    let newUserData = {
      firstName: selectedCustomer.firstName,
      lastName: selectedCustomer.lastName,
      email: selectedCustomer.emailAddress,
      optIn: false,
    };
    this.setState({
      userDataValid: true,
      user: newUserData,
    });

    this.props.dispatch(selectCustomer(selectedCustomer));
  };

  handleClearCustomer = () => {
    let newUserData = {
      firstName: "",
      lastName: "",
      email: "",
      optIn: false,
    };
    this.setState({
      userDataValid: false,
      user: newUserData,
    });
    this.props.dispatch(clearCustomerDetails());
  };

  handleReviewClick = () => {
    if (this.props.showCustomerDetails && !this.props.userSelected) {
      const userValidation = validateUser(this.state.user);
      if (userValidation.ok) {
        this.setState({
          userValidation: null,
        });
        this.props
          .dispatch(createUser(this.state.user))
          .then(() =>
            this.props.dispatch(push("/confirm"), (e) => console.log(e))
          );
      } else {
        this.setState({
          userValidation: userValidation,
          buttonClicked: true,
        });
      }
    } else {
      if (this.props.customFormId) {
        this.props.dispatch(push(`/form/${this.props.customFormId}`));
      } else {
        this.props.dispatch(push(`/confirm`));
      }
    }
  };

  render() {
    const {
      paymentSource,
      showCustomerDetails,
      userSelected,
      createUserError,
    } = this.props;
    const { firstName, lastName, email, optIn } = this.state.user;

    const selectValue = (option) => {
      if (
        (option.value === "stripe" && !showCustomerDetails) ||
        (option.value !== "stripe" &&
          showCustomerDetails &&
          !userSelected &&
          !this.state.user.firstName) ||
        (userSelected && !showCustomerDetails)
      ) {
        this.props.dispatch(toggleShowCustomerDetails());
      }
      this.props.dispatch(selectPaymentSource(option.value));
    };
    const buttonTite = showCustomerDetails
      ? "Skip Customer Details"
      : "Enter Customer Details";

    const handleToggleShowCustomerDetails = (evt) => {
      evt.preventDefault();
      const { showCustomerDetails } = this.props;
      if (showCustomerDetails) {
        this.handleClearCustomer();
      }
      this.props.dispatch(toggleShowCustomerDetails());
    };

    const checkoutDisabled =
      this.props.showCustomerDetails && !this.state.userDataValid;

    return (
      <div className={styles.root}>
        <div className={`${styles.backButton} registerBackButton`}>
          <Link to={"/"}>
            <span className={`${styles.backButton} registerBackButton`}>
              <FontAwesomeIcon icon="chevron-left" />
            </span>
            Back
          </Link>
        </div>
        <div>
          {this.props.paymentRequired && (
            <div className={styles.registerHeader}>
              <h3>Payment Method</h3>
              <Select
                name="payment"
                onChange={selectValue}
                options={paymentOptions}
                placeholder="Select payment Method"
                clearable={false}
                value={paymentOptions.filter(
                  (option) => option.value === paymentSource
                )}
                className={styles.paymentSelect}
              />
            </div>
          )}
          {(paymentSource !== "stripe" || !this.props.paymentRequired) && (
            <LaddaButton
              className={styles.customerDetailsButton}
              onClick={handleToggleShowCustomerDetails}
            >
              {buttonTite}
            </LaddaButton>
          )}
          {showCustomerDetails && (
            <UserForm
              firstName={firstName}
              lastName={lastName}
              email={email}
              optIn={optIn}
              validationResult={this.state.userValidation}
              serverError={createUserError}
              onInputChange={this.handleUserDataChange}
              onAutoSuggestDataChange={this.handleAutoSuggestDataChange}
              onSelectCustomer={this.handleSelectCustomer}
              disabled={userSelected}
              onClearCustomer={this.handleClearCustomer}
            />
          )}
          <div>
            <LaddaButton
              disabled={checkoutDisabled}
              loading={this.props.isRegisteringAndCheckingOut}
              className={
                checkoutDisabled
                  ? `${styles.confirmAndPayButton} ${styles.disabledButton}`
                  : styles.confirmAndPayButton
              }
              onClick={this.handleReviewClick}
            >
              Review Order
            </LaddaButton>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.item,
    customFormId: state.settings.customFormId,
    customers: state.customers,
    createUserError: state.user.createError,
    showCustomerDetails: state.showCustomerDetails,
    paymentSource: state.paymentSource,
    userSelected: state.user.isFetched,
    paymentRequired: getIsPaymentRequired(state),
  };
};
export default connect(mapStateToProps)(RegisterView);
