const getItemsInBasket = (state) => {
  return state.allIds.map((id) => state.byId[id]);
};

const getBasketQuantity = (state) =>
  getItemsInBasket(state).reduce((total, basketItem) => {
    total = total + basketItem.quantity;
    return total;
  }, 0);

const getTotal = (state) =>
  getItemsInBasket(state).reduce((total, basketItem) => {
    total = total + basketItem.quantity * basketItem.ticket.total;
    return total;
  }, 0);

const getTax = (state) =>
  getItemsInBasket(state).reduce((total, basketItem) => {
    total = total + basketItem.quantity * basketItem.ticket.tax;
    return total;
  }, 0);

export const getBasketItemCount = (state) =>
  getBasketQuantity(state.basket.tickets) +
  getBasketQuantity(state.basket.seasonTickets) +
  getBasketQuantity(state.basket.products);
export const getBasketTotal = (state) =>
  getTotal(state.basket.tickets) +
  getTotal(state.basket.seasonTickets) +
  getTotal(state.basket.products);
export const getBasketTax = (state) =>
  getTax(state.basket.tickets) +
  getTax(state.basket.seasonTickets) +
  getTax(state.basket.products);
export const getIncludeFees = (state) => state.basket.includeFees;
export const getBasketCurrency = (state) => state.currency;
export const getBasketDiscount = (state) =>
  state.discount.ticketDiscount + state.discount.feeDiscount;
export const getTicketsInBasket = (state) =>
  getItemsInBasket(state.basket.tickets);
export const getSeasonTicketsInBasket = (state) =>
  getItemsInBasket(state.basket.seasonTickets);
export const getProductsInBasket = (state) =>
  getItemsInBasket(state.basket.products);
export const getDonationAmount = (state) => state.donation.amount;
export const getGiftAid = (state) => state.donation.giftAid;
export const getIsPaymentRequired = (state) => {
  const total = (
    getBasketTotal(state) +
    getDonationAmount(state) -
    getBasketDiscount(state)
  ).toFixed(2);
  return total > 0;
};

export const getSelectedPerformanceSoldOut = (state) => {
  const tickets = state.ticketsByPerformance[state.selectedPerformance.id] || {
    isFetching: false,
    items: [],
  };
  const totalSum = tickets.items.reduce(
    (sum, t) =>
      sum +
      (t.capacity
        ? Math.min(t.capacity.left, t.inventoryLeft)
        : t.inventoryLeft),
    0
  );
  return tickets.isFetching || tickets.items.length === 0
    ? false
    : totalSum === 0;
};

export const getOrdersForPage = (state, page) =>
  state.pastOrders.pastOrdersByPage[page]
    ? state.pastOrders.pastOrdersByPage[page].items
    : [];
export const getOrdersCurrentPage = (state) =>
  state.pastOrders.pastOrdersByPage.currentPage;
export const getOrdersTotalPages = (state) =>
  state.pastOrders.pastOrdersByPage.totalPages;
export const getBasketIsValid = (state) =>
  !!(getBasketItemCount(state) > 0 && state.basket.validSelection);

export const getUser = (state) => state.user.item;

export const getEventId = (state) => state.event.item.id;
export const getCollections = (state) => state.collections;
export const getProductId = (state) => state.product.item.id;
