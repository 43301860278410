import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./SeatingChart.module.css";
import Spinner from "../Spinner";
import { currencyCodeToSymbol, formatPrice } from "utils/Currency";
import ReactDOMServer from "react-dom/server";
const DIV_ID = "seating_chart";

class SeatingChart extends Component {
  static propTypes = {
    publicKey: PropTypes.string.isRequired,
    eventKey: PropTypes.string.isRequired,
    tickets: PropTypes.array.isRequired,
    seatSelectedHandler: PropTypes.func.isRequired,
    seatUnSelectedHandler: PropTypes.func.isRequired,
    performance: PropTypes.object.isRequired,
    basket: PropTypes.object.isRequired,
    capacity: PropTypes.object.isRequired,
    onChartRendered: PropTypes.func,
    mainColor: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const spinner = ReactDOMServer.renderToString(<Spinner />);
    const prices = this.getPricingData();
    const availability = this.getAvailability();
    this.seatingChart = new window.seatsio.SeatingChart({
      divId: DIV_ID,
      publicKey: this.props.publicKey,
      event: this.props.eventKey,
      maxSelectedObjects: availability,
      showLegend: this.props.showLegend,
      selectionValidators: this.props.noOrphanSeats
        ? [{ type: "noOrphanSeats" }]
        : [],
      loading: spinner,
      pricing: prices,
      priceFormatter: this.getPricingFormat,
      onObjectSelected: this.handleObjectSelected,
      onObjectDeselected: this.handleObjectUnSelected,
      unavailableCategories: this.props.unavailableCategories,
      objectIcon: this.objectIcon,
      onChartRendered: this.props.onChartRendered,
      multiSelectEnabled: this.props.multiSelectEnabled,
      session: "continue",
      objectColor: this.objectColor,
      priceLevelsTooltipMessage: "Please choose a ticket type:",
      extraConfig: { mainColor: this.props.mainColor },
      tooltipInfo: this.tooltipText,
      isObjectSelectable: this.isObjectSelectable,
      fitTo: "widthAndHeight",
      onSelectionValid: this.props.onSelectionValid,
      onSelectionInvalid: this.props.onSelectionInvalid,
      objectWithoutPricingSelectable: true,
    });
    this.seatingChart.render();
  }

  componentWillUnmount() {
    this.seatingChart.destroy();
  }

  tooltipText = (object) => {
    if (object.category && object.category.label === "Wheelchair") {
      return "Please contact the box office to book";
    }
    if (object.status === "held") {
      return "Held seat";
    }
  };

  getPricingData = () => {
    let { tickets } = this.props;
    let prices = tickets.reduce((pricing, item) => {
      (pricing[item.categoryId] = pricing[item.categoryId] || []).push({
        ticketType: item.description,
        price: item.total,
      });
      return pricing;
    }, {});
    let pricingData = Object.keys(prices).map((catId) => {
      let categoryPrices = prices[catId];
      if (categoryPrices.length === 1) {
        return {
          category: catId,
          price: categoryPrices[0].price,
        };
      }
      return {
        category: catId,
        ticketTypes: categoryPrices,
      };
    });
    return pricingData;
  };

  getAvailability = () => {
    const availabilityDict = this.props.tickets.reduce((items, item) => {
      items[item.description] = Math.max(
        item.inventoryLeft,
        items[item.description] || 0
      );
      return items;
    }, {});
    const availability = Object.keys(availabilityDict).map((i) => {
      return {
        ticketType: i,
        quantity: availabilityDict[i],
      };
    });
    return availability;
  };

  getSelectedSeats = () => {
    const { basket, performance } = this.props;
    const selectedObjects = basket.allIds.reduce((objs, itemId) => {
      let item = basket.byId[itemId];
      if (item.performance.id === performance.id) {
        return objs.concat(item.seats);
      }
      return objs;
    }, []);
    return selectedObjects;
  };

  getPricingFormat = (price) => {
    let currencySymbol = currencyCodeToSymbol(this.props.tickets[0].currency);
    let isFree = price === 0;
    if (isFree) {
      return "Free";
    } else {
      return currencySymbol + formatPrice(price);
    }
  };

  getTicketFromObject = (object, selectedTicketType) => {
    const selectedTicket = this.props.tickets.find((ticket) => {
      return (
        object.category.key === ticket.categoryId &&
        (!selectedTicketType ||
          selectedTicketType.ticketType === ticket.description)
      );
    });
    return selectedTicket;
  };

  handleObjectSelected = (object, selectedTicketType) => {
    if (object.objectType === "Seat") {
      if (this.getSelectedSeats().includes(object.label)) {
        return;
      }
    }
    const selectedTicket = this.getTicketFromObject(object, selectedTicketType);
    if (selectedTicket) {
      this.props.seatSelectedHandler(
        selectedTicket,
        this.props.performance,
        1,
        object.label
      );
    }
  };

  handleObjectUnSelected = (object, selectedTicketType) => {
    const selectedTicket = this.getTicketFromObject(object, selectedTicketType);
    if (selectedTicket) {
      this.props.seatUnSelectedHandler(
        selectedTicket,
        this.props.performance,
        1,
        object.label
      );
    }
  };

  objectIcon = (object, defaultIcon, extraConfig) => {
    if (object.category && object.category.label === "Wheelchair") {
      return "wheelchair";
    }
    if (object.status === "held") {
      return "pause-circle";
    }
    return defaultIcon ? defaultIcon.icon : null;
  };

  isObjectSelectable = (object, defaultValue, extraConfig) => {
    if (object.status === "held") {
      return true;
    }
    return defaultValue;
  };

  objectColor = (object, defaultColor, extraConfig) => {
    if (object.selected === true) {
      return extraConfig.mainColor;
    }
    return defaultColor;
  };

  render() {
    return (
      <div
        id={DIV_ID}
        className={`${styles.seatingRoot} seatingChartSeatingRoot`}
      ></div>
    );
  }
}

export default SeatingChart;
