import React from "react";
import PropTypes from "prop-types";
import BasketItemRow from "components/BasketItemRow";

import styles from "./BasketSummary.module.css";
import { currencyCodeToSymbol, formatPrice } from "utils/Currency";
import { getBookingFeeText } from "utils/Text";

const BasketSummary = ({
  ticketItems,
  seasonTicketItems,
  productItems,
  currency,
  includeFees,
  handleBasketItemRemove,
  ticketDiscount,
  feeDiscount,
  donation,
  giftAid,
  onShowDiscountClick,
  onRemoveBookingFeesClick,
}) => {
  const onRemoveBookingFee = (evt) => {
    evt.preventDefault();
    onRemoveBookingFeesClick();
  };

  /**
   * Get basket total (inc fees) and subtotal (ex fees)
   *
   */
  const getBasketTotals = () => {
    let subTotal = 0;
    let basketTotal = 0;
    let bookingFees = 0;
    let basketTax = 0;
    let discountAmount = ticketDiscount + feeDiscount;
    let donationAmount = donation;
    for (let item of ticketItems) {
      let itemBasePrice = item.ticket.feesPassed
        ? item.ticket.totalExFees
        : item.ticket.total;
      let itemBookingFees = item.ticket.feesPassed
        ? item.ticket.total - item.ticket.totalExFees
        : 0;
      subTotal += itemBasePrice * item.quantity;
      basketTotal += item.ticket.total * item.quantity;
      basketTax += item.ticket.tax * item.quantity;
      bookingFees += itemBookingFees * item.quantity;
      discountAmount -= item.ticket.totalTicketDiscount
        ? item.ticket.totalTicketDiscount
        : 0;
    }
    for (let item of seasonTicketItems) {
      let itemBasePrice = item.ticket.feesPassed
        ? item.ticket.totalExFees
        : item.ticket.total;
      let itemBookingFees = item.ticket.feesPassed
        ? item.ticket.total - item.ticket.totalExFees
        : 0;
      subTotal += itemBasePrice * item.quantity;
      basketTotal += item.ticket.total * item.quantity;
      basketTax += item.ticket.tax * item.quantity;
      bookingFees += itemBookingFees * item.quantity;
    }
    for (let item of productItems) {
      let itemBasePrice = item.ticket.total;
      subTotal += itemBasePrice * item.quantity;
      basketTotal += item.ticket.total * item.quantity;
    }

    basketTotal -= discountAmount;
    basketTotal += donationAmount;
    if (!includeFees) {
      basketTotal -= bookingFees;
      bookingFees = 0;
    }
    return {
      subTotal,
      basketTotal,
      bookingFees,
      discountAmount,
      donationAmount,
      basketTax,
    };
  };

  let currencySymbol = currency ? currencyCodeToSymbol(currency) : "";
  let {
    subTotal,
    basketTotal,
    bookingFees,
    discountAmount,
    donationAmount,
    basketTax,
  } = getBasketTotals();
  let feeText = currency && basketTax > 0 ? getBookingFeeText(currency) : "";

  const ticketsByEvent = ticketItems.reduce((rv, item) => {
    (rv[item.event.id] = rv[item.event.id] || []).push(item);
    return rv;
  }, {});

  const lineItems = Object.keys(ticketsByEvent).map((id) => ticketsByEvent[id]);

  const getLineItem = (lineItem) => {
    let row = lineItem.map((item) => (
      <BasketItemRow
        item={item}
        key={item.ticket.id}
        onRemoveClick={handleBasketItemRemove}
      />
    ));
    return (
      <div className={styles.eventSummary} key={lineItem[0].event.id}>
        <h4 className={styles.eventTitle}>{lineItem[0].event.title}</h4>
        {row}
      </div>
    );
  };
  const giftAidAmount = donationAmount * 0.25;
  return (
    <div>
      {lineItems.map((lineItem) => getLineItem(lineItem))}
      {seasonTicketItems.length > 0 && (
        <h4 className={styles.multiPassTitle}>Multi Passes</h4>
      )}
      {seasonTicketItems.map((item) => (
        <BasketItemRow
          item={item}
          key={item.ticket.id}
          onRemoveClick={handleBasketItemRemove}
        />
      ))}
      {productItems.map((item) => (
        <BasketItemRow
          item={item}
          key={item.ticket.id}
          onRemoveClick={handleBasketItemRemove}
        />
      ))}
      <div className={styles.basketTotalsContainer}>
        {bookingFees > 0 && (
          <div>
            <div className={styles.subTotal}>
              <p>Subtotal</p>
              <p className={styles.subTotalFigure}>
                {currencySymbol}
                {formatPrice(subTotal)}
              </p>
            </div>
            <div className={styles.subTotal}>
              <p>
                Booking fees{" "}
                <button
                  className={styles.removeFees}
                  onClick={onRemoveBookingFee}
                >
                  (Remove)
                </button>
              </p>
              <p className={styles.subTotalFigure}>
                {currencySymbol}
                {formatPrice(bookingFees)}
              </p>
            </div>
          </div>
        )}
        {discountAmount > 0 && (
          <div className={styles.subTotal}>
            <p>Discount</p>
            <p className={styles.subTotalFigure}>
              - {currencySymbol}
              {formatPrice(discountAmount)}
            </p>
          </div>
        )}
        {donationAmount > 0 && (
          <div className={styles.subTotal}>
            <p>Donation</p>
            <p className={styles.subTotalFigure}>
              {currencySymbol}
              {formatPrice(donationAmount)}
            </p>
          </div>
        )}
        {donationAmount > 0 && giftAid && (
          <div className={styles.giftAid}>
            <p>Gift Aid</p>
            <p className={styles.giftAidAmount}>
              {currencySymbol}
              {formatPrice(giftAidAmount)}
            </p>
          </div>
        )}

        <div className={styles.basketTotalContainer}>
          <div className={styles.basketTotal}>Total</div>
          <div className={styles.basketFigure}>
            {currencySymbol}
            {formatPrice(basketTotal)}
          </div>
          <div className={styles.basketTotalfooter}>{feeText}</div>
        </div>
      </div>
    </div>
  );
};

BasketSummary.propTypes = {
  ticketItems: PropTypes.array.isRequired,
  seasonTicketItems: PropTypes.array.isRequired,
  productItems: PropTypes.array.isRequired,
  currency: PropTypes.string.isRequired,
  handleBasketItemRemove: PropTypes.func.isRequired,
  ticketDiscount: PropTypes.number,
  feeDiscount: PropTypes.number,
};

export default BasketSummary;
