import React from "react";
import { connectAutoComplete } from "react-instantsearch/connectors";
import Autosuggest from "react-autosuggest";
import theme from "./Autocomplete.module.css";
import styles from "./Clear.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { renderErrorForField, renderServerErrorForField } from "utils/Misc";

const AutoComplete = ({
  hits,
  refine,
  value,
  disabled,
  placeholder,
  onChange,
  onSelectCustomer,
  onClearCustomer,
  serverErrors,
  clientErrors,
  name,
}) => {
  const inputProps = {
    placeholder,
    value,
    onChange,
    disabled,
  };
  let clientsideError = renderErrorForField(name, clientErrors);
  let serversideError = renderServerErrorForField(name, serverErrors);

  let hasError = clientsideError || serversideError ? true : false;
  let className = hasError ? styles.error : null;
  return (
    <div>
      <Autosuggest
        theme={theme}
        suggestions={hits}
        multiSection={false}
        onSuggestionsFetchRequested={({ value }) => refine(value)}
        onSuggestionsClearRequested={() => {}}
        shouldRenderSuggestions={(value) =>
          !disabled && value.trim().length > 2
        }
        onSuggestionSelected={onSelectCustomer}
        getSuggestionValue={(hit) => hit.emailAddress}
        inputProps={inputProps}
        renderSuggestion={(hit) => (
          <div>
            <span>{hit.emailAddress}</span> -{" "}
            <span>
              {hit.firstName} {hit.lastName}
            </span>
          </div>
        )}
        renderInputComponent={(inputProps) => (
          <div className={className}>
            {disabled && (
              <FontAwesomeIcon
                icon="times-circle"
                onClick={onClearCustomer}
                className={styles.clear}
              />
            )}
            {clientsideError}
            {serversideError}
            <input {...inputProps} />
          </div>
        )}
      />
    </div>
  );
};
export default connectAutoComplete(AutoComplete);
