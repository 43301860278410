import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import styles from "./Booking.module.css";

import DateTimeBar from "components/DateTimeBar";
import TicketTable from "components/TicketTable";
import SeasonTicketTable from "components/SeasonTicketTable";
import Spinner from "components/Spinner";
import Footer from "containers/Footer";
import SeatingChart from "components/SeatingChart";
import {
  basketAddTicket,
  basketAddSeasonTicket,
  basketRemoveTicket,
  basketRemoveSeasonTicket,
  createReservation,
  selectPerformance,
  fetchTicketsIfNeeded,
  showBasket,
  openCalendar,
  closeCalendar,
  seatingChartLoaded,
  fetchSeasonTicketsIfNeeded,
  updateReservation,
  showListings,
  bookNowClick,
  setBasketValid,
  setBasketInvalid,
  selectDate,
} from "actions";
import { getBasketCurrency, getBasketItemCount } from "selectors";

class BookingView extends React.Component {
  static propTypes = {
    event: PropTypes.object.isRequired, // state.event.item
    basket: PropTypes.object.isRequired, // state.basket.items
    basketItemCount: PropTypes.number.isRequired,
    auth: PropTypes.object.isRequired, // state.auth
    loading: PropTypes.bool.isRequired, // state.reservation.isReserving
    performances: PropTypes.array.isRequired, // state.performances.items
    booking: PropTypes.string, // state.booking.item
    bookingFetched: PropTypes.bool.isRequired, // state.booking.isFetched
    capacity: PropTypes.object.isRequired, // state.capacity.items
    selectedPerformance: PropTypes.object.isRequired,
    ticketsByPerformance: PropTypes.object.isRequired,
    seasonTicketsByPerformance: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
  };

  componentDidMount = () => {
    const {
      dispatch,
      selectedPerformance,
      reservationReference,
      isReserved,
      shouldFetchReservation,
      performances,
    } = this.props;
    if (selectedPerformance.id) {
      dispatch(fetchTicketsIfNeeded(selectedPerformance.id));
      dispatch(fetchSeasonTicketsIfNeeded(selectedPerformance.id));
    } else {
      if (performances.length > 0) {
        dispatch(fetchTicketsIfNeeded(performances[0].id));
      }
    }
    if (reservationReference && !isReserved && shouldFetchReservation) {
      dispatch(updateReservation(reservationReference));
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.selectedPerformance.id &&
      nextProps.selectedPerformance.id !== this.props.selectedPerformance.id
    ) {
      const { dispatch, selectedPerformance } = nextProps;
      dispatch(fetchTicketsIfNeeded(selectedPerformance.id));
      dispatch(fetchSeasonTicketsIfNeeded(selectedPerformance.id));
    }
  }

  /**
   * Called when a date is clicked
   *
   */
  handleDateClick = (performances, evt) => {
    if (performances.length > 1) {
      this.props.dispatch(selectDate(performances[0].startDate));
    } else {
      this.props.dispatch(selectDate(performances[0].startDate));
      this.props.dispatch(selectPerformance(performances[0]));
    }
  };

  /**
   * Called when a time is clicked
   *
   */
  handleTimeClick = (performance, evt) => {
    this.props.dispatch(selectPerformance(performance));
  };

  /**
   * Called when the booking info link is clicked
   *
   */
  handleBookingLinkClick = () => {
    const link = {
      pathname: "/booking-info",
      state: {
        modal: true,
        returnTo: "/",
      },
    };
    this.props.dispatch(push(link));
  };

  /**
   * Called when the basket info link is clicked
   *
   */
  handleBasketLinkClick = () => {
    if (this.props.basketItemCount === 0) {
      return;
    }
    this.props.dispatch(showBasket());
  };

  /**
   * Called when increment ticket is clicked
   *
   */
  handleIncrementClick = (ticket, performance, quantity, seat) => {
    const event = this.props.event;
    this.props.dispatch(
      basketAddTicket(event, performance, ticket, quantity, seat)
    );
  };

  handleSeasonTicketIncrementClick = (seasonTicket, performance) => {
    const event = this.props.event;
    let quantity = 1;
    this.props.dispatch(
      basketAddSeasonTicket(event, performance, seasonTicket, quantity)
    );
  };

  /**
   * Called when decrement ticket is clicked
   *
   */
  handleDecrementClick = (ticket, performance, quantity, seat) => {
    const event = this.props.event;
    this.props.dispatch(
      basketRemoveTicket(event, performance, ticket, quantity, seat)
    );
  };

  handleSeasonTicketDecrementClick = (seasonTicket, performance) => {
    const event = this.props.event;
    let quantity = 1;
    this.props.dispatch(
      basketRemoveSeasonTicket(event, performance, seasonTicket, quantity)
    );
  };

  /**
   * Called when the book button is clicked
   */
  handleBookClick = () => {
    const { collections, basketItemCount, dispatch } = this.props;
    // make sure basket has items
    if (basketItemCount === 0) {
      return;
    }
    if ("parentIFrame" in window) {
      window.parentIFrame.scrollToOffset(0, 0);
    }
    if (collections) {
      const nextCollectionId = collections[0];
      return dispatch(push(`/product-collection/${nextCollectionId}`));
    }

    dispatch(bookNowClick());
    dispatch(createReservation());
  };

  handleCalendarOpenClick = (evt) => {
    this.props.dispatch(openCalendar());
  };

  handleListingsLinkClick = (evt) => {
    const reserve = this.props.basketItemCount > 0;
    this.props.dispatch(showListings(reserve));
  };

  handleWaitingListClick = (evt) => {
    this.props.dispatch(push("/waiting-list"));
  };

  handleCalendarCloseClick = (evt) => {
    this.props.dispatch(closeCalendar());
  };

  handleChartRendered = () => {
    this.props.dispatch(seatingChartLoaded(this.props.selectedPerformance.id));
  };
  handleSelectionValid = () => {
    this.props.dispatch(setBasketValid());
  };
  handleSelectionInvalid = () => {
    this.props.dispatch(setBasketInvalid());
  };
  handleChangeEvent = () => {
    this.props.dispatch(push("/events"));
  };

  render = () => {
    const {
      selectedPerformance,
      ticketsByPerformance,
      seasonTicketsByPerformance,
      basket,
      selectedDate,
    } = this.props;
    const tickets = ticketsByPerformance[selectedPerformance.id] || {
      isFetching: false,
      items: [],
    };
    const seasonTickets = seasonTicketsByPerformance[
      selectedPerformance.id
    ] || {
      isFetching: false,
      items: [],
    };
    let ticketNode = () => {
      if (tickets.isFetching || seasonTickets.isFetching) {
        return <Spinner />;
      } else if (selectedPerformance.id && selectedPerformance.seatingChart) {
        return (
          <SeatingChart
            publicKey={selectedPerformance.seatingChart.key}
            eventKey={`${selectedPerformance.id}`}
            showLegend={false}
            multiSelectEnabled={false}
            noOrphanSeats={this.props.noOrphanSeats}
            tickets={tickets.items}
            seatSelectedHandler={this.handleIncrementClick}
            seatUnSelectedHandler={this.handleDecrementClick}
            performance={selectedPerformance}
            basket={basket.tickets}
            capacity={this.props.capacity}
            onChartRendered={this.handleChartRendered}
            mainColor={this.props.settings.mainColor}
            onSelectionValid={this.handleSelectionValid}
            onSelectionInvalid={this.handleSelectionInvalid}
          />
        );
      } else if (selectedPerformance.id) {
        return (
          <div>
            <TicketTable
              tickets={tickets.items}
              performance={selectedPerformance}
              basket={basket.tickets.byId}
              onIncrementClick={this.handleIncrementClick}
              onDecrementClick={this.handleDecrementClick}
              capacity={this.props.capacity}
              lowInventoryLevel={this.props.lowInventoryLevel}
            />
            <SeasonTicketTable
              seasonTickets={seasonTickets.items}
              performance={selectedPerformance}
              basket={basket.seasonTickets.byId}
              onIncrementClick={this.handleSeasonTicketIncrementClick}
              onDecrementClick={this.handleSeasonTicketDecrementClick}
              capacity={this.props.capacity}
            />
          </div>
        );
      } else if (selectedDate) {
        return (
          <div className={styles.noTickets}>
            <h3>Please select a time from the options above</h3>
          </div>
        );
      } else {
        return (
          <div className={styles.noTickets}>
            <h3>No tickets are available for this event</h3>
          </div>
        );
      }
    };
    return (
      <div>
        {this.props.isRedirectingToListings ? (
          <Spinner />
        ) : (
          <div className={styles.root}>
            <h1 className={styles.title}>
              {this.props.event.title}:{" "}
              <span
                className={styles.changeEventBtn}
                onClick={this.handleChangeEvent}
              >
                (Change Event)
              </span>
            </h1>
            {selectedDate && (
              <DateTimeBar
                performances={this.props.performances}
                onDateClick={this.handleDateClick}
                onTimeClick={this.handleTimeClick}
                onBookingLinkClick={this.handleBookingLinkClick}
                onBasketLinkClick={this.handleBasketLinkClick}
                booking={!!this.props.booking}
                bookingFetched={this.props.bookingFetched}
                selectedPerformance={selectedPerformance}
                selectedDate={this.props.selectedDate}
                ticketsByPerformance={this.props.ticketsByPerformance}
                basketItemCount={this.props.basketItemCount}
                showCalendar={this.props.showCalendar}
                onCalendarOpenClick={this.handleCalendarOpenClick}
                onCalendarCloseClick={this.handleCalendarCloseClick}
                currency={this.props.currency}
              />
            )}
            {ticketNode()}
            <Footer
              loading={this.props.loading}
              onBookClick={this.handleBookClick}
              onListingsClick={this.handleListingsLinkClick}
              onJoinWaitingListClick={this.handleWaitingListClick}
            />
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  const {
    selectedPerformance,
    settings,
    seasonTicketsByPerformance,
    performances,
    reservation,
    selectedDate,
  } = state;
  let perfs;
  if (settings.singlePerformance) {
    perfs = performances.items.filter((perf) => {
      return perf.id === selectedPerformance.id;
    });
  }
  const basketItemCount = getBasketItemCount(state);

  return {
    event: state.event.item,
    currency: getBasketCurrency(state),
    basketItemCount: basketItemCount,
    collections: state.collections,
    basket: state.basket,
    auth: state.auth,
    loading: state.reservation.isReserving,
    performances: settings.singlePerformance ? perfs : performances.items,
    booking: state.booking.item,
    bookingFetched: state.booking.isFetched,
    capacity: state.capacity.items,
    ticketsByPerformance: state.ticketsByPerformance,
    ticketDiscount: state.discount.ticketDiscount,
    showCalendar: state.showCalendar,
    settings,
    selectedPerformance,
    selectedDate,
    seasonTicketsByPerformance,
    reservationReference: reservation.item.reference,
    isReserved: reservation.isReserved,
    isRedirectingToListings: state.flow.isRedirectingToListings,
    shouldFetchReservation: state.settings.listingsLink ? true : false,
    lowInventoryLevel: state.settings.lowInventoryLevel
      ? state.settings.lowInventoryLevel
      : 0,
    showPastOrders: state.settings.showPastOrders,
    noOrphanSeats: state.settings.noOrphanSeats
      ? state.settings.noOrphanSeats
      : false,
  };
};

export default connect(mapStateToProps)(BookingView);
