import React from "react";
import PropTypes from "prop-types";
import DayPicker from "react-day-picker";
import {
  isSameDay,
  getPerformanceStartDate,
  DATE_FORMATS,
} from "utils/DateFormatting";
import styles from "./Calendar.module.css";
import "./ReactDayPicker.css";
import { formatPrice, currencyCodeToSymbol } from "utils/Currency";
// import 'react-day-picker/lib/style.css';

export default class Calendar extends React.Component {
  static propTypes = {
    day: PropTypes.object.isRequired,
    performances: PropTypes.array.isRequired,
    onDayClick: PropTypes.func.isRequired,
    currency: PropTypes.string.isRequired,
  };

  static defaultProps = {
    performances: [],
    day: new Date(),
  };

  state = {
    selectedDay: null,
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      selectedDay: nextProps.day,
    });
  };

  componentWillMount = () => {
    this.setState({
      selectedDay: this.props.day,
    });
  };

  getFirstAndLastMonth = () => {
    let dates = this.props.performances.map((perf) =>
      getPerformanceStartDate(perf).toDate()
    );
    let sorted = dates.sort((a, b) => {
      return a - b;
    });

    let first = sorted[0];
    let last = sorted[sorted.length - 1];

    return { first, last };
  };

  renderDay = (day) => {
    let items = [];

    // get performances on this day
    for (let perf of this.props.performances) {
      let perfDay = getPerformanceStartDate(perf).toDate();
      let hasTickets = true;

      if (isSameDay(perfDay, day)) {
        items.push({
          performance: perf,
          hasTickets: hasTickets,
        });
      }
    }

    // no performances in this date cell? then just display the day
    if (items.length === 0) {
      return <div className={styles.dayDateContainer}>{day.getDate()}</div>;
    }

    let maxTimeNodes = 2; // max time elements to display
    const maxTotalBeforeSummary = 3;
    let timeNodes;
    let moreText;
    if (items.length >= maxTotalBeforeSummary) {
      let itemsWithAvailability = items.filter(
        (item) =>
          item.performance.availability === null ||
          (item.performance.availability > 0 &&
            item.performance.minTicketPrice !== null &&
            item.performance.minTicketPrice !== undefined &&
            item.performance.minTicketPrice >= 0)
      );
      let soldOut = true;
      let minPrice = 0;

      if (itemsWithAvailability.length > 0) {
        minPrice = itemsWithAvailability.reduce((min, item) => {
          min =
            item.performance.minTicketPrice < min
              ? item.performance.minTicketPrice
              : min;
          return min;
        }, itemsWithAvailability[0].performance.minTicketPrice);
        soldOut = false;
      }
      let className = soldOut
        ? `${styles.timeLozengeSoldOut} calendarTimeLozengeSoldOut`
        : `${styles.summaryLozenge} calendarSummaryLozenge`;
      timeNodes = (
        <div className={className}>
          {soldOut
            ? "SOLD OUT"
            : `From ${currencyCodeToSymbol(this.props.currency)}${formatPrice(
                minPrice
              )}`}
        </div>
      );
    } else {
      timeNodes = items.slice(0, maxTimeNodes).map((item, i) => {
        let isSoldOut =
          item.performance.availability !== null &&
          item.performance.availability === 0;
        let mDate = getPerformanceStartDate(item.performance);
        let className = isSoldOut
          ? `${styles.timeLozengeSoldOut} calendarTimeLozengeSoldOut`
          : `${styles.timeLozenge} calendarTimeLozenge`;
        let style = item.performance.color
          ? { backgroundColor: item.performance.color }
          : {};
        return (
          <div className={className} key={i} style={style}>
            {isSoldOut ? "SOLD OUT" : mDate.format(DATE_FORMATS.CALENDAR_TIME)}
          </div>
        );
      });
      moreText =
        items.length > maxTimeNodes ? (
          <span className={styles.moreTimesAvailable}>
            + {items.length - maxTimeNodes} more
          </span>
        ) : null;
    }

    let mobileColorStyle = items[0].performance.color
      ? { backgroundColor: items[0].performance.color }
      : {};
    return (
      <div>
        <div className={styles.dayDateContainer}>{day.getDate()}</div>
        <div className={styles.timesContainer}>
          {timeNodes}
          {moreText}
        </div>
        <div className={styles.timesContainerMobile}>
          <span
            style={mobileColorStyle}
            className={`${styles.dot} calendarDot`}
          ></span>
        </div>
      </div>
    );
  };

  handleDayClick = (day, modifiers, e) => {
    if (!modifiers.disabled) {
      this.props.onDayClick(day, e);
    }
  };

  render = () => {
    let modifiers = {
      selected: (day) => isSameDay(day, this.state.selectedDay),
    };

    let { first, last } = this.getFirstAndLastMonth();

    return (
      <DayPicker
        onDayClick={this.handleDayClick}
        renderDay={this.renderDay}
        initialMonth={this.state.selectedDay}
        modifiers={modifiers}
        fromMonth={first}
        toMonth={last}
      />
    );
  };
}
