import React from "react";
import PropTypes from "prop-types";
import { currencyCodeToSymbol, formatPrice } from "utils/Currency";
import styles from "./ProductRow.module.css";
import { LinkButton } from "components/LinkButton";

export default class ProductRow extends React.Component {
  static propTypes = {
    product: PropTypes.object.isRequired,
    quantity: PropTypes.number.isRequired,
    onIncrementClick: PropTypes.func.isRequired,
    onDecrementClick: PropTypes.func.isRequired,
  };

  /**
   * Returns true if more of this ticket can be added to the basket
   *
   */
  canQuantityBeIncremented = () => {
    if (this.props.product.maxPerOrder) {
      return this.props.quantity < this.props.product.maxPerOrder;
    }
    return true;
  };

  /**
   * Returns true if the item is sold out
   *
   */
  isSoldOut = () => {
    if (this.props.product.trackInventory) {
      return 0 >= this.props.product.inventoryLeft - this.props.quantity;
    }
    return false;
  };

  /**
   * Called when decrement button is clicked
   *
   */
  handleDecrementQuantity = (evt) => {
    evt.preventDefault();

    if (this.props.quantity > 0) {
      this.props.onDecrementClick(this.props.product);
    }
  };

  /**
   * Called when increment button is clicked
   *
   */
  handleIncrementQuantity = (evt) => {
    evt.preventDefault();

    if (this.canQuantityBeIncremented()) {
      this.props.onIncrementClick(this.props.product);
    }
  };

  /**
   * This will cut the description to `cutoff` length. The resulting text will
   * be `cutoff`+ 3 in length. It will also indicate whether the text was
   * trimmed, so that the tooltip can be excluded if there was no need to trim.
   *
   */
  getDescriptionCroppingInfo = () => {
    let text = this.props.product.title;
    let cropped = false;
    const cutoff = 150;

    if (text && text.length > cutoff) {
      text = text.substr(0, cutoff) + "...";
      cropped = true;
    }

    return { text, cropped };
  };

  /**
   * Returns the description wrapped in a tooltip or not, depending on the
   * length of the text
   *
   */
  getDescriptionBlock = () => {
    const { text, cropped } = this.getDescriptionCroppingInfo();

    if (cropped) {
      return (
        <div>
          <div
            className={styles.descriptionTooltip}
            data-balloon={this.props.product.description}
            data-balloon-pos={"up"}
            data-balloon-length={"medium"}
          >
            <span className={styles.description}>{text}</span>
          </div>
          <div
            className={styles.descriptionTooltipMobile}
            data-balloon={this.props.product.description}
            data-balloon-pos={"right"}
            data-balloon-length={"small"}
          >
            <span className={styles.description}>{text}</span>
          </div>
        </div>
      );
    } else {
      return (
        <span className={styles.description}>
          {text}
          <span className={styles.descriptionDetail}>
            {this.props.product.description}
          </span>
        </span>
      );
    }
  };

  getQuantityBlock = () => {
    const soldOut = this.isSoldOut();
    let addClassName = this.canQuantityBeIncremented()
      ? `${styles.add} ticketRowAdd`
      : `${styles.addDisabled} ticketRowAddDisabled`;
    let removeClassName =
      this.props.quantity === 0
        ? `${styles.removeDisabled} ticketRowRemoveDisabled`
        : `${styles.remove} ticketRowRemove`;
    if (soldOut) {
      return (
        <td className={styles.controlsColumn}>
          <span className={styles.soldOut}>Sold Out</span>
        </td>
      );
    } else {
      return (
        <td className={styles.controlsColumn}>
          <LinkButton
            className={removeClassName}
            onClick={this.handleDecrementQuantity}
          >
            -
          </LinkButton>
          <span className={styles.quantity}>{this.props.quantity}</span>
          <LinkButton
            className={addClassName}
            onClick={this.handleIncrementQuantity}
          >
            +
          </LinkButton>
        </td>
      );
    }
  };

  /**
   * Returns the pricing details for the ticket
   *
   */
  getPricingBlock = () => {
    let currencySymbol = currencyCodeToSymbol(this.props.product.currency);
    let isFree =
      this.props.product.total === 0 || this.props.product.total == null
        ? true
        : false;
    let total = this.props.product.total;
    if (isFree) {
      return (
        <td className={styles.pricesColumn}>
          <span className={styles.total}>FREE</span>
        </td>
      );
    } else {
      return (
        <td className={styles.pricesColumn}>
          <span className={styles.total}>
            {currencySymbol}
            {formatPrice(total)}
          </span>
        </td>
      );
    }
  };

  render = () => {
    return (
      <tr>
        <td className={styles.descriptionColumn}>
          {this.getDescriptionBlock()}
        </td>
        {this.getPricingBlock()}
        {this.getQuantityBlock()}
      </tr>
    );
  };
}
