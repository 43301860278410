export class ServerError {}

export class CreateUserError extends ServerError {
  constructor(response) {
    super();
    this.response = response;
  }
}

export class LoginUserError extends ServerError {
  constructor(response) {
    super();
    this.response = response;
  }
}

export class FetchCardsError extends ServerError {
  constructor(response) {
    super();
    this.response = response;
  }
}

export class CreateCardError extends ServerError {
  constructor(response) {
    super();
    this.response = response;
  }
}

export class CreateOrderError extends ServerError {
  constructor(response) {
    super();
    this.response = response;
  }
}

export class CreateReservationError extends ServerError {
  constructor(response) {
    super();
    this.response = response;
  }
}

export class FetchTicketsError extends ServerError {
  constructor(response) {
    super();
    this.response = response;
  }
}

export class SelectCardError extends ServerError {
  constructor(response) {
    super();
    this.response = response;
  }
}

export class FetchBookingInfoError extends ServerError {
  constructor(response) {
    super();
    this.response = response;
  }
}

export class FetchPerformancesError extends ServerError {
  constructor(response) {
    super();
    this.response = response;
  }
}

export class ForgotPasswordError extends ServerError {
  constructor(response) {
    super();
    this.response = response;
  }
}

export class AddDiscountCodeError extends ServerError {
  constructor(response) {
    super();
    this.response = response;
  }
}

export class FetchCustomersError extends ServerError {
  constructor(response) {
    super();
    this.response = response;
  }
}
