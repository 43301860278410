import React from "react";
import PropTypes from "prop-types";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCalendar,
  faShoppingCart,
  faChevronLeft,
  faInfoCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import { InstantSearch, Configure } from "react-instantsearch/dom";

import {
  fetchEvent,
  fetchProduct,
  logoutUser,
  expiredReservation,
  fetchAllPerformances,
  fetchUser,
  fetchEvents,
} from "actions";
import Footer from "components/Footer";
import Header from "components/Header";

import Modal from "components/Modal";
import CustomStyle from "components/CustomStyle";
import styles from "./App.module.css";

// Views
import { Home } from "./Home";
import BookingView from "views/Booking";
import BasketView from "views/Basket";
import RegisterView from "views/Register";
import AddressView from "views/AddressView";
import ConfirmView from "views/Confirm";
import LoginView from "views/Login";
import SuccessView from "views/Success";
import ReservationExpiredView from "views/ReservationExpired";
import BookingInfoView from "views/BookingInfo";
import ForgotPasswordView from "views/ForgotPassword";
import { AddDiscountView } from "views/AddDiscount";
import { CustomFormView } from "views/CustomForm";
import WaitingListView from "views/WaitingList";
import PastOrders from "views/PastOrders/PastOrders";
import OrderDetail from "views/PastOrders/OrderDetail";
import GiftAid from "views/GiftAid/GiftAid";
import GiftAidAddressView from "views/GiftAid/GiftAidAddress";
import ProductView from "views/Product";
import Events from "views/Events/Events";

library.add(
  faCalendar,
  faShoppingCart,
  faChevronLeft,
  faInfoCircle,
  faTimesCircle
);

class App extends React.Component {
  static propTypes = {
    eventIdentifier: PropTypes.oneOfType([
      // accepts an event ID or an event SLUG
      PropTypes.string,
      PropTypes.number,
    ]),
    productId: PropTypes.number,
    collection: PropTypes.object,
    dataFetched: PropTypes.bool.isRequired, // custom see mapStateToProps
    reservation: PropTypes.object.isRequired, // state.reservation
    order: PropTypes.object.isRequired, // state.order
    user: PropTypes.object.isRequired, // state.user
    auth: PropTypes.object.isRequired, // state.auth
  };

  componentWillMount() {
    if (this.props.productId) {
      this.props.dispatch(fetchProduct(this.props.productId));
    }
    if (this.props.eventIdentifier) {
      this.props.dispatch(fetchEvents());
      this.props.dispatch(fetchAllPerformances(this.props.eventIdentifier));
      this.props.dispatch(fetchEvent(this.props.eventIdentifier));
    }
    if (this.props.auth.loggedIn && this.props.auth.isAuthedViaCookie) {
      this.props.dispatch(fetchUser());
    }
    // if show card is false, but there are tickets, show the tickets view
  }

  componentWillReceiveProps(nextProps) {
    // if we changed routes...
    if (
      nextProps.location.key !== this.props.location.key &&
      nextProps.location.state &&
      nextProps.location.state.modal
    ) {
      this.background = this.props.location;
    } else {
      this.background = undefined;
    }
    if (!nextProps.dataFetched && this.props.dataFetched) {
      this.props.dispatch(fetchEvent(nextProps.eventIdentifier));
      this.props.dispatch(fetchAllPerformances(nextProps.eventIdentifier));
    }
  }

  /**
   * Called when the countdown timer has expired
   *
   */
  handleCountdownEnded = () => {
    this.props.dispatch(expiredReservation());
  };

  /**
   * Called when the the user clicks the logout button
   *
   */
  handleLogoutClick = () => {
    this.props.dispatch(push("/"));
    this.props.dispatch(logoutUser());
  };

  render() {
    // if we're still loading the data from the server, don't render anything
    // yet
    if (!this.props.dataFetched) {
      return null;
    }
    const location = this.props.location;
    let background = this.background;
    const isModal = location.state && location.state.modal;

    if (isModal) {
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.overflow = "auto";
    }

    return (
      <InstantSearch
        appId="1L4OH778M0"
        apiKey={this.props.auth.searchApiKey}
        indexName={this.props.auth.searchIndex}
      >
        <Configure hitsPerPage={5} />
        <div className={styles.root}>
          <CustomStyle settings={this.props.settings} />
          <Header
            reservation={this.props.reservation}
            order={this.props.order}
            onCountdownFinish={this.handleCountdownEnded}
          />

          <Switch location={background || location}>
            <Route path="/" exact component={Home} />
            <Route path="/event" component={BookingView} />
            <Route path="/events" component={Events} />
            <Route path="/product" component={ProductView} />
            <Route
              path="/product-collection/:collectionId"
              component={ProductView}
            />
            <Route path="/register" component={RegisterView} />
            <Route path="/address" component={AddressView} />
            <Route path="/form/:formId" component={CustomFormView} />
            <Route path="/login" component={LoginView} />
            <Route path="/confirm" component={ConfirmView} />
            <Route path="/success" component={SuccessView} />
            <Route path="/expired" component={ReservationExpiredView} />
            <Route path="/basket" component={BasketView} />
            <Route path="/add-discount" component={AddDiscountView} />
            <Route path="/forgot-password" component={ForgotPasswordView} />
            <Route path="/waiting-list" component={WaitingListView} />
            <Route path="/past-orders" component={PastOrders} />
            <Route path="/past-order" component={OrderDetail} />
            <Route path="/gift-aid" component={GiftAid} />
            <Route path="/gift-aid-address" component={GiftAidAddressView} />
          </Switch>

          {isModal && (
            <Switch>
              <Route
                path="/add-discount"
                children={
                  <Modal
                    isOpen={true}
                    returnTo={this.props.location.state.returnTo}
                  >
                    <AddDiscountView />
                  </Modal>
                }
              />
              <Route
                path="/basket"
                children={
                  <Modal
                    isOpen={true}
                    returnTo={this.props.location.state.returnTo}
                  >
                    <BasketView />
                  </Modal>
                }
              />
              <Route
                path="/booking-info"
                children={
                  <Modal
                    isOpen={true}
                    returnTo={this.props.location.state.returnTo}
                  >
                    <BookingInfoView />
                  </Modal>
                }
              />
            </Switch>
          )}

          <Footer
            onLogoutClick={this.handleLogoutClick}
            auth={this.props.auth}
            user={this.props.user.item}
          />
        </div>
      </InstantSearch>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    reservation,
    order,
    auth,
    user,
    settings,
    event,
    product,
    collection,
    collections,
  } = state;

  // event & ticket information must both be fetched first
  const dataFetched = state.event.item.id
    ? state.event.isFetched && state.performances.isFetched
    : product.item.id
    ? product.isFetched
    : collection.isFetched;

  return {
    eventIdentifier: event.item.id,
    productId: product.item.id,
    collectionId: collection.item.id,
    collections: collections,
    dataFetched,
    reservation,
    order,
    auth,
    user,
    settings,
    event: event.item,
  };
};

export default connect(mapStateToProps)(App);
