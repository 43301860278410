/**
 * All app data validation is here, all functions should return an object
 * in the format:
 *
 * {
 *  ok: false,
 *  errors: [
 *    {
 *      message: 'A title is required',
 *      path: 'title'
 *    }
 *  ]
 * }
 */

import validator from "validator";

// -- custom validators

validator.isNotEmpty = (str) => {
  let s = validator.trim(str);
  if (s.length > 0) {
    return true;
  }

  return false;
};

// -- wrapper around the validator library to support a schema based
// approach to validating objects

const v = (validatorFunc, errorMessage, options, extraOptions) => {
  return (value) => {
    let result = null;

    // If it's an optional validator - ie if there's data we'll run normal validation
    // otherwise we'll skip validation entirely
    if (extraOptions) {
      if (
        extraOptions.optional === true &&
        validator.isNull(validator.trim(value))
      ) {
        return {
          error: false,
          message: null,
        };
      }
    }

    // If options are supplied, we'll pass that to the actual validator
    if (options) {
      result = validatorFunc(value, options);
    } else {
      result = validatorFunc(value);
    }

    if (!result) {
      return {
        error: true,
        message: errorMessage,
      };
    } else {
      return {
        error: false,
        message: null,
      };
    }
  };
};

const validateSchema = (schema, data) => {
  let results = [];

  for (let fieldName in schema) {
    let fieldErrors = [];

    // run all validators against the field
    for (let validatorFunc of schema[fieldName]) {
      let validationResult = validatorFunc(data[fieldName]);
      if (validationResult.error) {
        fieldErrors.push({
          message: validationResult.message,
        });
      }
    }

    if (fieldErrors.length > 0) {
      results.push({
        path: fieldName,
        message: fieldErrors[0].message, // only add the first message
      });
    }
  }

  return results;
};

// -- validations for different app components are below

export const validateUser = (data) => {
  const schema = {
    firstName: [v(validator.isNotEmpty, "First name is required")],
    lastName: [v(validator.isNotEmpty, "Last name is required")],
    email: [
      v(validator.isNotEmpty, "Your email address is required"),
      v(validator.isEmail, "This must be a valid email address"),
    ],
  };

  const errors = validateSchema(schema, data);
  return {
    ok: errors.length > 0 ? false : true,
    errors: errors,
  };
};

/**
 * Used to check that all fields have some sort of data in them
 *
 */
export const validateUserNotEmpty = (data) => {
  const schema = {
    firstName: [v(validator.isNotEmpty, "First name is required")],
    lastName: [v(validator.isNotEmpty, "Last name is required")],
    email: [v(validator.isNotEmpty, "Your email address is required")],
  };

  const errors = validateSchema(schema, data);
  return {
    ok: errors.length > 0 ? false : true,
    errors: errors,
  };
};

export const validateDiscountCode = (data) => {
  const schema = {
    code: [v(validator.isNotEmpty, "Discount code cannot be empty")],
  };

  const errors = validateSchema(schema, data);
  return {
    ok: errors.length > 0 ? false : true,
    errors: errors,
  };
};
