import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { selectEvent } from "actions";
import styles from "./Events.module.css";

const EventRow = ({ event, onEventClick }) => {
  const handleEventClick = (evt) => {
    evt.preventDefault();
    onEventClick(event);
  };
  return (
    <div className={styles.eventRow}>
      <span className={styles.eventImage}>
        {event.image && <img src={event.image.url} alt="event" />}
      </span>
      <span className={styles.eventTitle} onClick={handleEventClick}>
        {event.title}
      </span>
    </div>
  );
};

export class Events extends React.Component {
  handleEventClick = (event) => {
    this.props.dispatch(selectEvent(event));
    this.props.dispatch(push("/"));
  };
  render() {
    const events = this.props.events.sort((a, b) =>
      a.title > b.title ? 1 : -1
    );
    return (
      <div className={styles.root}>
        <h1 className={styles.title}>Events</h1>
        {events.map((event) => (
          <EventRow
            key={event.id}
            event={event}
            onEventClick={this.handleEventClick}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    events: state.events.items,
  };
};

export default connect(mapStateToProps)(Events);
