import React from "react";
import InputField from "components/InputField";
import AutoComplete from "components/Autocomplete";
import styles from "./UserForm.module.css";

const UserForm = ({
  firstName,
  lastName,
  email,
  optIn,
  validationResult,
  serverError,
  onInputChange,
  onAutoSuggestDataChange,
  onClearCustomer,
  onSelectCustomer,
  disabled,
}) => {
  const getServerErrorHtml = () => {
    if (!serverError) {
      return;
    }

    let errorMetadata = serverError._metadata;

    // if there are errors mapped to fields that were sent in the request,
    // the form will display those errors underneath the input, so we can
    // skip displaying a generic error message
    if (errorMetadata.errors && errorMetadata.errors.length > 0) {
      return;
    }

    // if it's a generic error message from the server, show it here
    return (
      <div className={styles.error}>
        We've had an issue with your registration. Please ensure you have an
        internet connection and try again.
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <form className={styles.registerForm}>
        <div className={styles.registerFormForm}>
          {getServerErrorHtml()}
          <div className={styles.inputContainer}>
            <AutoComplete
              name="email"
              onSelectCustomer={onSelectCustomer}
              onClearCustomer={onClearCustomer}
              clientErrors={validationResult}
              serverErrors={serverError}
              onChange={onAutoSuggestDataChange}
              placeholder="Enter email to search for customer"
              value={email}
              disabled={disabled}
            />
          </div>
          <div className={styles.group}>
            <div className={styles.groupInputContainer}>
              <InputField
                name="firstName"
                type="text"
                value={firstName}
                onChange={onInputChange}
                placeholder="First name"
                clientErrors={validationResult}
                serverErrors={serverError}
                className={styles.nameInput}
                disabled={disabled}
              />
            </div>
            <div className={styles.groupInputContainer}>
              <InputField
                name="lastName"
                type="text"
                value={lastName}
                onChange={onInputChange}
                placeholder="Last name"
                clientErrors={validationResult}
                serverErrors={serverError}
                className={styles.nameInput}
                disabled={disabled}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.marketingConsentLabel}>
                Does this buyer consent to be added to your mailing list?
                <input
                  name="optIn"
                  type="checkbox"
                  checked={optIn}
                  onChange={onInputChange}
                  className={styles.marketingConsentCheckbox}
                  disabled={disabled}
                />
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserForm;
