import React from "react";
import PropTypes from "prop-types";
import ShimmedInput from "components/PlaceholderShim";
import styles from "./InputField.module.css";

import { renderErrorForField, renderServerErrorForField } from "utils/Misc";

export default class InputField extends React.Component {
  static propTypes = {
    serverErrors: PropTypes.object,
    clientErrors: PropTypes.object,
  };

  render() {
    let clientsideError = renderErrorForField(
      this.props.name,
      this.props.clientErrors
    );
    let serversideError = renderServerErrorForField(
      this.props.name,
      this.props.serverErrors
    );
    let hasError = clientsideError || serversideError ? true : false;
    let className = hasError ? styles.error : null;
    let inputProps = Object.assign({}, this.props);
    delete inputProps["serverErrors"];
    delete inputProps["clientErrors"];

    return (
      <div>
        <ShimmedInput className={className} {...inputProps} />
        {clientsideError}
        {serversideError}
      </div>
    );
  }
}
